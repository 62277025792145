import { baseUrl } from "variables/general.js"

const curateDate = (inputDate) => {
  const dateYear = inputDate.getFullYear();
  const dateMonth = ("0" + (inputDate.getMonth() + 1)).slice(-2);
  const dateDay = ("0" + inputDate.getDate()).slice(-2);
  const curatedDate = dateYear + "-" + dateMonth + "-" + dateDay;
  return curatedDate;
}

const getAllAppLogs = (setTableData, onError, token) => {
  const queryUrl = baseUrl + "AppLogs";

  fetch(queryUrl, {
    method: "GET",
    headers: {
      "Authorization": [token],
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((res) => res.json())
    .then((fetchedData) => {
      setTableData(fetchedData);
    })
    .catch((err) => {
      onError(err);
    });
};

const createAppLog = (body, onSuccess, onError, token) => {
  const queryUrl = baseUrl + "AppLogs";
  //console.log(body);
  body.log_date = curateDate(body.log_date);
  fetch(queryUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      "Authorization": token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((response) => {
      //onSuccess(response.status);
    })
    .catch((err) => {
      onError(err);
    });
};


export { getAllAppLogs, createAppLog };
