import { baseUrl } from "variables/general.js"

const getAllVariables = (setTableData, onError, token) => {
  const queryUrl = baseUrl + "ConfigVariables";

  fetch(queryUrl, {
    method: "GET",
    headers: {
      "Authorization": [token],
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((res) => res.json())
    .then((fetchedData) => {
      setTableData(fetchedData[0]);
    })
    .catch((err) => {
      onError(err);
    });
};

const createVariable = (body, onSuccess, onError, token) => {
  const queryUrl = baseUrl + "ConfigVariables";
  // creates entity
  fetch(queryUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      "Authorization": token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((response) => {
      onSuccess(response.status);
    })
    .catch((err) => {
      onError(err);
    });
};

const updateVariable = (body, onSuccess, onError, token) => {
  // this will update entries with PUT
  const queryUrl = baseUrl + "ConfigVariables";
  fetch(queryUrl, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      "Authorization": token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((response) => {
      onSuccess(response.status);
    })
    .catch((err) => {
      onError(err);
    });
};

const removeVariable = (onSuccess, onError, token) => {

};

export { getAllVariables, createVariable, updateVariable, removeVariable };
