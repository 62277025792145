import { baseUrl } from "variables/general.js"
import axios from "axios";

export const refreshTokenSetup = (token, expiryDate, setUserToken, setUserPrivilege) => {

  const fiveMinutes = 5 * 60 * 1000; // Five minutes in milliseconds

  const refreshIfExpiringSoon = () => {
    const currentTime = Date.now();
    const timeUntilExpiry = expiryDate - currentTime;

    if (timeUntilExpiry <= fiveMinutes) {
      // Less than or equal to 5 minutes to expiration, refresh the access token
      refreshToken();
    } else {
      // Schedule the next check based on remaining time until expiration
      setTimeout(refreshToken, timeUntilExpiry - fiveMinutes);
    }
  };

  const refreshToken = async () => {
    try {
      const res = await axios.post(baseUrl + 'auth/google/refresh-token', { token });
      console.log('Token refreshed!');
      setUserToken(res.data.tokens.access_token)
      setUserPrivilege(res.data.privilege_level);
      // Schedule the next refresh 5 minutes before the new access token expires
      const currentTime = Date.now();
      const timeUntilExpiry = res.data.tokens.expiry_date - currentTime;
      console.log("Time until expiry = " + timeUntilExpiry)
      setTimeout(refreshToken, timeUntilExpiry - fiveMinutes);
    } catch (error) {
      console.error('Error refreshing access token:', error);
      // Handle error, e.g., redirect to login page or display an error message
    }
  };
  
  // Start the initial refresh check
  setTimeout(refreshIfExpiringSoon, expiryDate - Date.now() - fiveMinutes);
};