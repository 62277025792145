import React, { useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import spaLocale from "date-fns/locale/es";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { isEmpty } from "validator";
import {
  statusMap,
  typeMap,
  orderFrequencyMap,
  clientDefault
} from "variables/general"
//Context
import { UserNameContext, UserEmailContext } from "components/GeneralContextProvider/GeneralContextProvider"


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  button: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function ClientDialog(props) {
  
  const { onAdd, onEdit, representatives, editedClient, isOpen, setIsOpen, isSee, setIsSee, isEditDisabled, setDidStatusChange } = props;

  const classes = useStyles();

  const userName = useContext(UserNameContext);
  const userEmail = useContext(UserEmailContext);

  const originalStatus = Number(editedClient.status);

  const [client, setClient] = useState(clientDefault);
  const [clientError, setClientError] = useState({
    client_name: false,
    client_code: false,
    owner_representative_name: false,
    capturing_representative_name: false,
    contact_info: false,
    note: false,
    anniversary: false,
    products: false,
    type: false,
    status: false,
    order_frequency: false,
  });

  useEffect(() => {
    if(editedClient.client_code) {
      setClient(editedClient);
    } else {
      resetFields()
    }
  }, [editedClient]);

  function handleChange(event) {

    const { value, name } = event.target;

    if (name === "client_name") {
      setClient((prevState) => {
        return {
        ...prevState,
        client_name: value,
      };
    });
    }

    if (name === "client_code") {
      setClient((prevState) => {
      return {
        ...prevState,
        client_code: value,
      };
    });
    }

    if (name === "owner_representative_name") {
      const filteredRepresentative = representatives.filter(
        (representative) =>
          representative.fname + " " + representative.lname === value
      );
      setClient((prevState) => {
      return {
        ...prevState,
        owner_representative_name: value,
        owner_representative_id: filteredRepresentative[0].id,
      }
      });
    }

    if (name === "capturing_representative_name") {
      const filteredRepresentative = representatives.filter(
        (representative) =>
          representative.fname + " " + representative.lname === value
      );
      setClient((prevState) => {
      return {
        ...prevState,
        capturing_representative_name: value,
        capturing_representative_id: filteredRepresentative[0].id,
      }
      });
    }

    if (name === "contact_info") {
      setClient((prevState) => {
      return {
        ...prevState,
        contact_info : value,
      };
    });
    }

    if (name === "note") {
      setClient((prevState) => {
      return {
        ...prevState,
        note: value,
      };
    });
    }

    if (name === "products") {
      setClient((prevState) => {
      return {
        ...prevState,
        products: value,
      };
    });
    }

    if (name === "type") {
      setClient((prevState) => {
      return {
        ...prevState,
        type: value,
      };
    });
    }

    if (name === "order_frequency") {
      setClient((prevState) => {
      return {
        ...prevState,
        order_frequency: value,
      };
    });
    }

    if (name === "status") {
      if (value !== originalStatus) {
        setDidStatusChange(true);
      } else {
        setDidStatusChange(false);
      }
      setClient((prevState) => {
      return {
        ...prevState,
        status: value,
      };
    });
    }
    validateField(!isEmpty(value.toString()), name);
  }

  if (name === "order_frequency") {
    setClient((prevState) => {
    return {
      ...prevState,
      order_frequency: value,
    };
  });
  }

  const validateField = (validator, fieldName) => {
    if (validator) {
      setClientError((prevState) => {
        return { ...prevState, [fieldName]: false };
      });
      return true;
    } else {
      setClientError((prevState) => {
        return { ...prevState, [fieldName]: true };
      });
      return false;
    }
  };

  function validateAll() {
    const check1 = validateField(!isEmpty(client.client_name), "client_name");
    const check2 = validateField(!isEmpty(client.client_code), "client_code");
    const check3 = validateField(!isEmpty(client.owner_representative_name), "owner_representative_name");
    const check4 = validateField(!isEmpty(client.capturing_representative_name), "capturing_representative_name");
    return check1 && check2 && check3 && check4;
  }

  function resetFields() {
    setClient(clientDefault);
    setClientError({
      client_name: false,
      client_code: false,
      owner_representative_id: false,
      owner_representative_name: false,
      capturing_representative_id: false,
      capturing_representative_name: false,
      note: false,
      contact_info: false,
      products: false,
      type: false,
      status: false,
      order_frequency: false,
    });
    setDidStatusChange(false);
  }

  const handleClose = () => {
    //resetFields();
    setIsOpen(false);
    setIsSee(false);
  };

  const handleClickSave = () => {
    if (!validateAll()) {
      return;
    }
    if (isSee) {
      onEdit(client)
    } else {
      onAdd(client);
    }

    resetFields();
    setIsOpen(false);
    setIsSee(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle>Cliente</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <TextField
              error={clientError.client_name}
              disabled={isEditDisabled}
              onChange={handleChange}
              value={client.client_name}
              name="client_name"
              label="Nombre"
              style={{ margin: 8 }}
              placeholder=""
              helperText={clientError.client_name && "Este campo no puede estar vacio"}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              error={clientError.client_code}
              disabled={isSee}
              onChange={handleChange}
              value={client.client_code}
              name="client_code"
              label="RIF"
              style={{ margin: 8 }}
              helperText={clientError.client_code && "Este campo no puede estar vacio"}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              select
              error={clientError.owner_representative_name}
              disabled={isEditDisabled}
              onChange={handleChange}
              value={client.owner_representative_name}
              name="owner_representative_name"
              label="Vendedor asignado"
              style={{ margin: 8 }}
              placeholder=""
              helperText={
                clientError.owner_representative_name &&
                "Este campo no puede estar vacio"
              }
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            >
              {representatives.map((representative) => (
                <MenuItem
                  key={representative.id}
                  value={representative.fname + " " + representative.lname}
                >
                  {representative.fname + " " + representative.lname}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              error={clientError.capturing_representative_name}
              disabled={isEditDisabled}
              onChange={handleChange}
              value={client.capturing_representative_name}
              name="capturing_representative_name"
              label="Capturado por"
              style={{ margin: 8 }}
              placeholder=""
              helperText={
                clientError.capturing_representative_name &&
                "Este campo no puede estar vacio"
              }
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            >
              {representatives.map((representative) => (
                <MenuItem
                  key={representative.id}
                  value={representative.fname + " " + representative.lname}
                >
                  {representative.fname + " " + representative.lname}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              multiline
              disabled={isEditDisabled}
              onChange={handleChange}
              value={client.contact_info}
              name="contact_info"
              label="Información de contacto"
              style={{ margin: 8 }}
              placeholder=""
              helperText=""
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              multiline
              disabled={isEditDisabled}
              onChange={handleChange}
              value={client.note}
              name="note"
              label="Nota"
              style={{ margin: 8 }}
              placeholder=""
              helperText=""
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              multiline
              disabled={isEditDisabled}
              onChange={handleChange}
              value={client.products}
              name="products"
              label="Productos de interés"
              style={{ margin: 8 }}
              placeholder=""
              helperText=""
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={spaLocale}>
              <KeyboardDatePicker
                clearable
                disabled={isEditDisabled}
                style={{ margin: 16, marginLeft: 8 }}
                label="Fecha de aniversario"
                value={client.anniversary}
                onChange={(date) =>
                  setClient((prevState) => {
                    return {
                      ...prevState,
                      anniversary: date,
                    };
                  })
                }
                format="dd MMMM"
                //views={['month', 'day']}
                //renderInput={(params) => <TextField {...params} helperText={null} />}
              />
            </MuiPickersUtilsProvider>
            <TextField
              select
              disabled={isEditDisabled}
              onChange={handleChange}
              value={client.status}
              name="status"
              label="Estado"
              className={classes.textField}
              helperText=""
              margin="normal"
            >
              {Object.entries(statusMap).map(option => (
                <MenuItem key={option[0]} value={option[0]}>
                  {option[1]}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              disabled={isEditDisabled}
              onChange={handleChange}
              value={client.type}
              name="type"
              label="Tipo"
              className={classes.textField}
              helperText=""
              margin="normal"
            >
              {Object.entries(typeMap).map(option => (
                <MenuItem key={option[0]} value={option[0]}>
                  {option[1]}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              disabled={isEditDisabled}
              onChange={handleChange}
              value={client.order_frequency}
              name="order_frequency"
              label="Frequencia compra"
              className={classes.textField}
              helperText=""
              margin="normal"
            >
              {Object.entries(orderFrequencyMap).map(option => (
                <MenuItem key={option[0]} value={option[0]}>
                  {option[1]}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClickSave} disabled={isEditDisabled} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

//https://dev.to/alejluperon/react-form-validation-39bi
