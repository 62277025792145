import React, { useEffect, useState, useContext } from "react";
import MUIDataTable from "mui-datatables";
import MUIDataTableSpanish from "variables/MUIDataTablesSpanish";
import AppUserModal from "components/AppUserModal/AppUserModal";
import { makeStyles } from "@material-ui/core/styles";
import { Menu, MenuItem, MenuList } from '@mui/material'; 
import Button from "@material-ui/core/Button";
import ErrorModal from "components/ErrorModal/ErrorModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import NotificationPanel from "components/NotificationPanel/NotificationPanel";

import {
  customCellStyleSmall,
  customCellStyleMedium,
  customCellStyleLarge,
  customCellStylePrivilege,
} from "variables/cellStyles";
//Context
import { 
  AppUserTableContext, 
  UpdateAppUserTableContext, 
  UpdateErrorStatusContext, 
  UserContext, 
  UserNameContext,
  UserPrivilegeContext,
  UpdateConfigVariableContext, 
  ConfigVariableContext 
} from "components/GeneralContextProvider/GeneralContextProvider"
import {
  getAllAppUsers,
  createAppUser,
  updateAppUser,
  removeAppUser,
} from "repositories/AppUserRepository";
import {
  getAllVariables,
  updateVariable,
} from "repositories/ConfigVariableRepository";
import {
  privilegeMap,
  appUserDefault
} from "variables/general"

//RBAC
import Can from "components/Can/Can"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  button: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const AdminPanelTable = () => {

  const classes = useStyles();

  const userName = useContext(UserNameContext);
  const userPrivilege = useContext(UserPrivilegeContext);

  const [contextSee, setContextSee] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);

  const [open, setOpen] = useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);

  const [editedAppUser, setEditedAppUser] = useState(appUserDefault);
  const [forbiddenQuery, setForbiddenQuery] = useState(false);

  const tableState = useContext(AppUserTableContext);
  const setTableState = useContext(UpdateAppUserTableContext);
  const setErrorStatus = useContext(UpdateErrorStatusContext);
  const userToken = useContext(UserContext);
  const configVariableState = useContext(ConfigVariableContext);
  const setConfigVariableState = useContext(UpdateConfigVariableContext);

  useEffect(() => {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        loading: true,
      };
    });
    getAllAppUsers(setTableData, onQueryError, userToken);
    getAllVariables(setConfigVariableData, onQueryError, userToken);
  }, []);

  function addAppUser(appUser) {
    createAppUser(
      appUser,
      onQuerySuccess,
      onQueryError,
      userToken
    );
  }

  function editAppUser(appUser) {
    updateAppUser(
      appUser,
      onQuerySuccess,
      onQueryError,
      userToken
    );
  }

  function deleteAppUser(appUser) {
    let appUserId = appUser.id;
      removeAppUser(
        appUserId,
        onQuerySuccess,
        onQueryError,
        userToken
      );
  }

  function editVariable(body) {
    updateVariable(
      body,
      onQuerySuccess,
      onQueryError,
      userToken
    );
  }

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : 
          null,
    );
    
  };

  const handleContextClose = () => {
    setContextMenu(null);
  };

  const handleContextSee = () => {
    setContextSee(true)
    setOpen(true)
    setContextMenu(null);
  };

  const handleContextDeletePrompt = () => {
    setContextMenu(null)
    setConfirmationModalOpen(true)
  };

  const handleContextDelete = () => {
    if (editedAppUser) {
      deleteAppUser(editedAppUser)
      setEditedAppUser(appUserDefault)
    }
    setContextMenu(null);
  };

  const handleClickOpen = () => {
    setEditedAppUser(appUserDefault)
    setOpen(true);
  };

  function handleRowClick(rowData, rowMeta) {
    const appUser = tableState.data[rowMeta.dataIndex];
    setEditedAppUser(appUser);
  }

  const handleDoubleClick = (row, dataIndex) => {
    const appUser = tableState.data[dataIndex]
    handleContextSee()
  }

  const handleMouseOver = (row, dataIndex) => {
    const appUser = tableState.data[dataIndex]
    setEditedAppUser(appUser)
  }

  function onQuerySuccess(statusCode) {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        loading: true,
      };
    });
    getAllAppUsers(setTableData, onQueryError, userToken);
  }

  function onQueryError(error) {
    let statusCode = parseInt(error.message);
    if (statusCode===403) {
      setForbiddenQuery(true);
    } else if (statusCode===401) {
      //Here goes some modal about unauthorized operation
    } else {
      setErrorStatus(true);
    }
  }

  function setTableData(fetchedData) {
    setTableState({ data: fetchedData, loading: false });
  }

  function setConfigVariableData(fetchedData) {
    setConfigVariableState({ data: fetchedData, loading: false });
  }

  const columns = [
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleLarge,
      },
    },
    {
      name: "privilege_level",
      label: "Nivel de privilegio",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStylePrivilege,
      },
    },
    {
      name: "last_seen",
      label: "Visto por última vez",
      options: {
        filter: false,
        sort: true,
        customBodyRender: customCellStyleLarge,
      },
    },
  ];

  const options = {
    ...MUIDataTableSpanish,
    selectableRows: "none",
    filterType: "textField",
    filter: true,
    sort: true,
    download: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    onRowClick: handleRowClick,
    onRowsDelete: false,
    // sortOrder: {
    //   name: 'email',
    //   direction: 'asc'
    // },
    setRowProps: (row, dataIndex) => ({
      onDoubleClick: () => {
        handleDoubleClick(row, dataIndex)
      },
      onMouseOver: () => {
        handleMouseOver(row, dataIndex)
      }
    })
  };

  return (
    <div>
      <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
        <MUIDataTable
          title={"Lista de Usuarios"}
          data={tableState.data}
          columns={columns}
          options={options}
        />
        <Menu
            open={contextMenu !== null}
            onClose={handleContextClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            <MenuList>
              <MenuItem onClick={handleContextSee}>Ver/Editar</MenuItem>
              <MenuItem 
                onClick={handleContextDeletePrompt}
                disabled={isDeleteDisabled}
                >
                  Borrar
              </MenuItem>
            </MenuList>
        </Menu>
      </div>
      <Can 
          role={userPrivilege} 
          perform="appUsers:edit"
          data={[]} 
          yes={() => {
            setIsEditDisabled(false);
            return null
          }}
          no={() => {
            setIsEditDisabled(true);
            return null
          }}
      />
      <Can 
          role={userPrivilege} 
          perform="appUsers:delete"
          data={[]} 
          yes={() => {
            setIsDeleteDisabled(false);
            return null
          }}
          no={() => {
            setIsDeleteDisabled(true);
            return null
          }}
      />
      <Button
        disabled={isEditDisabled}
        variant="contained"
        size="large"
        color="primary"
        onClick={handleClickOpen}
        className={classes.button}
      >
        Agregar
      </Button>
      <AppUserModal 
        onAdd={addAppUser}
        onEdit={editAppUser}
        editedAppUser={editedAppUser}
        isOpen={open}
        setIsOpen={setOpen}
        isSee={contextSee}
        setIsSee={setContextSee}
        isEditDisabled={isEditDisabled}
      />
      <NotificationPanel onEdit={editVariable} configVariableData={configVariableState.data} />
      <ConfirmationModal 
        isOpen={confirmationModalOpen}
        setIsOpen={setConfirmationModalOpen}
        onSuccess={handleContextDelete}
      />
      {forbiddenQuery && <ErrorModal setOpen={setForbiddenQuery} />}
    </div>
  );
};

export default AdminPanelTable;

//https://www.smashingmagazine.com/2020/06/rest-api-react-fetch-axios/
//https://itnext.io/centralizing-api-error-handling-in-react-apps-810b2be1d39d
