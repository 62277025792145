//const baseUrl = "http://localhost:3001/api/"

const baseUrl = "https://crm.plastisurca.com/api/"

const clientId = "816786373130-qia4ks1egpir1hmq1s3n3ks61blvcr6g.apps.googleusercontent.com"

const privilegeMap = {
  0: "Usuario básico", 
  1: "Usuario avanzado",
  2: "Admin"
}

const isOrderMap = {
  0: "No compra", 
  1: "Compra"
}

const executedMap = {
  0: "Por ejecutar", 
  1: "Ejecutado"
}

const statusMap = {
  0: "Inactivo", 
  1: "Activo"
}

const typeMap = {
  0: "No ideal", 
  1: "Ideal"
}

const orderFrequencyMap = {
  0: "Semanal",
  1: "Quincenal", 
  2: "Mensual", 
  3: "Bimestral", 
  4: "Trimestral", 
  5: "Semestral", 
  6: "Anual"
}

const activityDefault = {
  id: 0,
  name: "",
  client_code: "",
  current_status: "",
  description: "",
  representative_name: "",
  representative_id: "",
  action_date: new Date(),
  is_order: 0,
  executed: 0,
}

const clientDefault = {
  client_name: "",
  client_code: "",
  owner_representative_name: "",
  capturing_representative_name: "",
  contact_info: "",
  note: "",
  anniversary: null,
  products: "",
  type: 1,
  status: 1,
  order_frequency: 6,
}

const representativeDefault = {
  fname: "",
  lname: "",
  contact_info: "",
  note: "",
  status: 1,
}

const contactDefault = {
  id: 0,
  fname: "",
  lname: "",
  email: "",
  phone_number: "",
  birthday: null,
  client_code: "",
  client_name: "",
}

const appUserDefault = {
  id: 0,
  email: "",
  privilege_level: "",
  note: "",
}

module.exports = {
  baseUrl,
  clientId,
  privilegeMap,
  isOrderMap,
  executedMap,
  statusMap,
  typeMap,
  orderFrequencyMap,
  activityDefault,
  clientDefault,
  representativeDefault, 
  contactDefault, 
  appUserDefault
};
