import React, { useState, useEffect, useContext } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button, makeStyles, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core"
import { FormGroup, FormControlLabel, FormControl, Checkbox } from '@mui/material';
import { isEmpty, isDate } from "validator";
import DateFnsUtils from "@date-io/date-fns";
import spaLocale from "date-fns/locale/es";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  activityDefault,
  clientDefault
} from "variables/general"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
}));

export default function ActivityDialog(props) {
  const { onAdd, onEdit, clients, isOpen, setIsOpen, isSee, setIsSee, isEditDisabled, editedActivity } = props;

  const classes = useStyles();

  const [activity, setActivity] = useState(activityDefault);
  const [activityError, setActivityError] = useState({
    name: true,
    client_code: false,
    current_status: false,
    description: false,
    representative_name: false,
    action_date: false,
    is_order: false,
    executed: false,
  });

  useEffect(() => {
    if(editedActivity.id) {
      setActivity(editedActivity);
      setClientAutocompleteValue(prevState => {
        return {
          ...prevState,
          client_code: editedActivity.client_code,
          client_name: editedActivity.name
        };
      });
    } else {
      resetFields()
    }
  }, [editedActivity]);
  

  //These two are for the autocomplete component
  const [clientAutocompleteValue, setClientAutocompleteValue] = useState(clientDefault);
  const [clientAutocompleteInput, setClientAutocompleteInput] = useState("");

  function handleChange(event) {
    const { value, name, checked } = event.target;

    if (name === "current_status" || name === "description" || name === "action_date") {
      setActivity((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  
    if (name === "is_order" || name === "executed") {
      setActivity((prevState) => ({
        ...prevState,
        [name]: checked ? 1 : 0,
      }));
    }
    
    validateField(!isEmpty(value.toString()), name);
  }

  const validateField = (validator, fieldName) => {
    if (validator) {
      setActivityError((prevState) => {
        return { ...prevState, [fieldName]: false };
      });
      return true;
    } else {
      setActivityError((prevState) => {
        return { ...prevState, [fieldName]: true };
      });
      return false;
    }
  };

  function validateAll() {
    const check1 = validateField(!isEmpty(activity.client_code), "client_code");
    const check2 = validateField(!isEmpty(activity.description), "description");
    const check3 = validateField(
      !isEmpty(activity.current_status),
      "current_status"
    );
    const check4 = validateField(
      !isEmpty(activity.representative_name),
      "representative_name"
    );
    return check1 && check2 && check3 && check4;
  }

  function resetFields() {
    setActivity(activityDefault);
    setActivityError({
      name: false,
      client_code: false,
      current_status: false,
      description: false,
      representative_name: false,
      action_date: false,
      is_order: false,
      executed: false,
    });
    setClientAutocompleteValue(clientDefault);
    setClientAutocompleteInput("");
  }

  const handleClose = () => {
    //resetFields();
    setIsOpen(false)
    setIsSee(false)
  };

  const handleClickSave = () => {
    if (!validateAll()) {
      return;
    }
    if (isSee) {
      onEdit(activity)
    } else {
      onAdd(activity)
    }
    resetFields();
    setIsOpen(false);
    setIsSee(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle>Actividad</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Autocomplete
              error={activityError.name}
              disabled={isEditDisabled}
              value={clientAutocompleteValue || ""}
              onChange={(event, newClient) => {
                setClientAutocompleteValue(newClient);
                if (newClient) {
                  setActivity((prevState) => {
                    return {
                      ...prevState,
                      client_code: newClient.client_code,
                      name: newClient.client_name,
                      representative_name: newClient.owner_representative_name,
                    };
                  });
                }
              }}
              inputValue={clientAutocompleteInput}
              onInputChange={(event, input) => {
                setClientAutocompleteInput(input);
              }}
              options={clients}
              getOptionLabel={(client) => client.client_name || ""}
              getOptionSelected={(option, value) => option.id === value.id}
              name="name"
              label="Cliente"
              placeholder=""
              helperText={
                activityError.name &&
                "Este campo no puede estar vacio"
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cliente"
                  margin="normal"
                  style={{ margin: 8 }}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <TextField
              disabled
              error={activityError.client_code}
              onChange={handleChange}
              value={activity.client_code}
              name="client_code"
              label="RIF"
              style={{ margin: 8 }}
              placeholder=""
              helperText={
                activityError.client_code &&
                "Este campo no puede estar vacio"
              }
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              multiline
              error={activityError.current_status}
              disabled={isEditDisabled}
              onChange={handleChange}
              value={activity.current_status}
              name="current_status"
              label="Estado actual"
              style={{ margin: 8 }}
              placeholder=""
              helperText={
                activityError.current_status &&
                "Este campo no puede estar vacio"
              }
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              multiline
              error={activityError.description}
              disabled={isEditDisabled}
              onChange={handleChange}
              value={activity.description}
              name="description"
              label="Próximo paso"
              style={{ margin: 8 }}
              placeholder=""
              helperText={
                activityError.description && "Este campo no puede estar vacio"
              }
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              disabled
              error={activityError.representative_name}
              onChange={handleChange}
              value={activity.representative_name}
              name="representative_name"
              label="Vendedor"
              style={{ margin: 8 }}
              placeholder=""
              helperText=""
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={spaLocale}>
              <KeyboardDatePicker
                clearable
                disabled={isEditDisabled}
                style={{ margin: 16, marginLeft: 8 }}
                label="Fecha de actividad"
                value={activity.action_date}
                onChange={(value) => {
                  setActivity((prevState) => {
                    return {
                      ...prevState,
                      action_date: value,
                    };
                  })
                }}
                format="dd/MM/yyyy"
              />
            </MuiPickersUtilsProvider>

            <FormControl fullWidth>
              <FormGroup>
                <FormControlLabel control={
                  <Checkbox 
                    name="is_order"
                    disabled={isEditDisabled}
                    checked={activity.is_order == 1}
                    onChange={handleChange}
                  />
                } label="Actividad de Compra" />
              </FormGroup>
              <FormGroup>
                <FormControlLabel control={
                  <Checkbox 
                    name="executed"
                    disabled={isEditDisabled}
                    checked={activity.executed == 1}
                    onChange={handleChange}
                  />
                } label="Actividad Ejecutada" />
              </FormGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClickSave} disabled={isEditDisabled} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

//https://dev.to/alejluperon/react-form-validation-39bi
