import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Login from "components/Login/Login";
import { makeStyles } from "@material-ui/core/styles";
import rosefield from "assets/img/rosefield.jpg";
import machinery from "assets/img/machinery.jpg";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles((theme) => ({
  body: {
    width: "100vw",
    height: "100vh",
    background: "#121212",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "url(" + rosefield + ")",
  },


  mainContainer: {
    backgroundColor: "#FFFFFF",
    width: "400px",
    height: "400px",
    margin: "7em auto",
    borderRadius: "1.5em",
    boxShadow: "0px 11px 35px 2px rgba(0, 0, 0, 0.14)",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "10%",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "8%",
  },
  loginButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "10%",
  },
}));

function LoginPage() {
  const classes = useStyles();

  return (
     <div className={classes.body}>
      <GridContainer>
        <GridItem xs={12} md={4}>
          <div className={classes.mainContainer}>
          <div className={classes.title}>
            <h3>Inicio de sesión</h3>
          </div>
          <div className={classes.icon}>
            <AccountCircleIcon style={{ fontSize: 130 }} />
          </div>
          <div className={classes.loginButton}>
            <Login />
          </div>
          </div>
        </GridItem>
      </GridContainer>
     </div>
  );
}

export default LoginPage;
