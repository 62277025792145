/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useContext } from "react";
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import TaskIcon from '@mui/icons-material/Task';
import AccountCircle from "@material-ui/icons/AccountCircle"
import AssignmentIcon from '@material-ui/icons/Assignment';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import Activities from "views/Activities/Activities.js"
import Clients from "views/Clients/Clients.js"
import Representatives from "views/Representatives/Representatives.js"
import Contacts from "views/Contacts/Contacts.js"
import AppLogs from "views/AppLogs/AppLogs.js"
import AdminPanel from "views/AdminPanel/AdminPanel.js"

const dashboardRoutes = [
    {
      path: "/dashboard",
      name: "Panel central",
      icon: Dashboard,
      component: DashboardPage,
      layout: "/admin",
      privilegeReq: 0,
    },
    {
      path: "/activities",
      name: "Actividades",
      icon: TaskIcon,
      component: Activities,
      layout: "/admin",
      privilegeReq: 0,
    },
    {
      path: "/clients",
      name: "Clientes",
      icon: AccountCircle,
      component: Clients,
      layout: "/admin",
      privilegeReq: 0,
    },
    {
      path: "/contacts",
      name: "Contactos",
      icon: AccountBoxIcon,
      component: Contacts,
      layout: "/admin",
      privilegeReq: 0,
    },
    {
      path: "/representatives",
      name: "Vendedores",
      icon: Person,
      component: Representatives,
      layout: "/admin",
      privilegeReq: 0,
    },
    {
      path: "/logs",
      name: "Logs",
      icon: AssignmentIcon,
      component: AppLogs,
      layout: "/admin",
      privilegeReq: 1,
    },
    {
      path: "/panel",
      name: "Panel de administrador",
      icon: SupervisorAccountIcon,
      component: AdminPanel,
      layout: "/admin",
      privilegeReq: 2,
    },
];


export default dashboardRoutes
