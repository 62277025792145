import { baseUrl } from "variables/general.js"

const getAllContacts = (setTableData, onError, token) => {
  const queryUrl = baseUrl + "Contacts";
  fetch(queryUrl, {
    method: "GET",
    headers: {
      "Authorization": token,
    },
  })
    .then((res) => res.json())
    .then((fetchedData) => {
      setTableData(fetchedData);
    })
    .catch((err) => {
      onError(err);
    });
};

const getClientContacts = (code, setTableData, onError, token) => {
  let params = {
    fromClient: 1,
    clientCode: code
  };
  let queryUrl = new URL(baseUrl + "Contacts");
  queryUrl.search = new URLSearchParams(params).toString();
  fetch(queryUrl, {
    method: "GET",
    headers: {
      "Authorization": token,
    },
  })
    .then((res) => res.json())
    .then((fetchedData) => {
      setTableData(fetchedData);
    })
    .catch((err) => {
      onError(err);
    });
};

const createContact = (body, onSuccess, onError, token) => {
  const queryUrl = baseUrl + "Contacts";
  // creates entity
  fetch(queryUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      "Authorization": token,
    },
    body: JSON.stringify(body),
  })
  .then((res) => {
    if (!res.ok) {
      throw Error(res.status);
    }
    return res;
  })
    .then((response) => {
      onSuccess(response.status);
    })
    .catch((err) => {
      onError(err);
    });
};

const updateContact = (body, onSuccess, onError, token) => {
  // this will update entries with PUT
  const queryUrl = baseUrl + "Contacts";
  fetch(queryUrl, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      "Authorization": token,
    },
    body: JSON.stringify(body),
  })
  .then((res) => {
    if (!res.ok) {
      throw Error(res.status);
    }
    return res;
  })
    .then((response) => {
      onSuccess(response.status);
    })
    .catch((err) => {
      onError(err);
    });
};

const removeContact = (contactId, onSuccess, onError, token) => {
  const idParam = { id: contactId };
  var queryUrl = new URL(baseUrl + "Contacts");
  queryUrl.search = new URLSearchParams(idParam).toString();
  // deletes entities
  fetch(queryUrl, {
    method: "DELETE",
    headers: {
      "Authorization": token,
    },
  })
  .then((res) => {
    if (!res.ok) {
      throw Error(res.status);
    }
    return res;
  })
  .then((response) => {
    onSuccess(response.status);
  })
  .catch((err) => {
    onError(err);
  });
};

export { getAllContacts, getClientContacts, createContact, updateContact, removeContact };
