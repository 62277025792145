import React, { useEffect, useState, useContext } from "react";
import MUIDataTable from "mui-datatables";
import MUIDataTableSpanish from "variables/MUIDataTablesSpanish";
import ClientModal from "components/ClientModal/ClientModal";
import { makeStyles } from "@material-ui/core/styles";
import ErrorModal from "components/ErrorModal/ErrorModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {
  customCellStyleSmall,
  customCellStyleMedium,
  customCellStyleLarge,
  customCellStyleStatus,
  customCellStyleType,
  customCellStyleOrderFrequency,
} from "variables/cellStyles";
import {
  UpdateErrorStatusContext,
  UserContext,
  ClientTableContext,
  UpdateClientTableContext,
} from "components/GeneralContextProvider/GeneralContextProvider";
import {
  getAllClients,
  createClient,
  updateClient,
  removeClient,
} from "repositories/ClientRepository";
import { getAllRepresentatives } from "repositories/RepresentativeRepository"
import { createAppLog } from "repositories/AppLogRepository";
import { getClientContacts } from "repositories/ContactRepository"
//Context
import { UserPrivilegeContext, UserNameContext } from "components/GeneralContextProvider/GeneralContextProvider"
import { Menu, MenuItem, MenuList } from '@mui/material'; 
import Button from "@material-ui/core/Button";
import {
  statusMap,
  typeMap,
  orderFrequencyMap,
  clientDefault
} from "variables/general"
//RBAC
import Can from "components/Can/Can"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  button: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));


const ClientTable = () => {

  const classes = useStyles();

  const userName = useContext(UserNameContext);
  const userPrivilege = useContext(UserPrivilegeContext);

  const [contextSee, setContextSee] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);

  const [open, setOpen] = useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const [editedClient, setEditedClient] = useState(clientDefault);
  const [contactList, setContactList] = useState([]);

  const [didStatusChange, setDidStatusChange] = useState(false);
  
  //This flag is set to true when the backend returns HTTP status 403 - Forbidden request
  const [forbiddenQuery, setForbiddenQuery] = useState(false);

  const setErrorStatus = useContext(UpdateErrorStatusContext);
  const userToken = useContext(UserContext);
  const tableState = useContext(ClientTableContext);
  const setTableState = useContext(UpdateClientTableContext);

  const [representativeList, setRepresentativeList] = useState([]);

  let appLog = {
    log_type: "",
    log_description: "",
    log_date: new Date(),
  }

  useEffect(() => {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        loading: true,
      };
    });
    getAllClients(setTableData, onQueryError, userToken);
    getAllRepresentatives(setRepresentativeList, onQueryError, userToken);
  }, []);

  function addClient(client) {
    createClient(client, onQuerySuccess, onQueryError, userToken);
  }

  function addAppLog(appLog) {
    createAppLog(appLog, {}, onQueryError, userToken);
  }

  function editClient(client) {
    if (didStatusChange) {
      const new_status = Number(editedClient.status) === 1 ? "Inactivo" : "Activo"
      const descriptionText = 
        "El usuario " + userName + " actualizó el estado del cliente "
        + editedClient.client_name + " con RIF " + editedClient.client_code +
        " a " + new_status;
        appLog = {
          log_type: "Cambio de estado en cliente",
          log_description: descriptionText,
          log_date: new Date(),
        };
      setDidStatusChange(false)
      updateClient(client, onQuerySuccessLog, onQueryError, userToken);
    } else{
      updateClient(client, onQuerySuccess, onQueryError, userToken);
    }
  }

  function deleteClient(client) {
    appLog = {
      log_type: "Borrado de cliente",
      log_description: "El usuario " + userName + " borró el cliente "
      + editedClient.client_name + " con RIF " + editedClient.client_code,
      log_date: new Date(),
    };
    let clientCode = client.client_code;
    removeClient(clientCode, onQuerySuccessLog, onQueryError, userToken);
  }

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : 
          null,
    );
    
  };

  const handleContextClose = () => {
    setContextMenu(null);
  };

  const handleContextSee = () => {
    setContextSee(true)
    setOpen(true)
    setContextMenu(null);
  };

  const handleContextDeletePrompt = () => {
    setContextMenu(null)
    setConfirmationModalOpen(true)
  };

  const handleContextDelete = () => {
    if (editedClient) {
      deleteClient(editedClient)
      setEditedClient(clientDefault)
    }
    setContextMenu(null);
  };

  const handleClickOpen = () => {
    setEditedClient(clientDefault)
    setOpen(true);
  };

  function handleRowClick(rowData, rowMeta) {
    const client = tableState.data[rowMeta.dataIndex];
    setEditedClient(client);
    getClientContacts(client.client_code, setContactList, onQueryError, userToken);
  }

  const handleDoubleClick = (row, dataIndex) => {
    const client = tableState.data[dataIndex]
    handleContextSee()
  }

  const handleMouseOver = (row, dataIndex) => {
    const client = tableState.data[dataIndex]
    setEditedClient(client)
    getClientContacts(client.client_code, setContactList, onQueryError, userToken);
  }

  function onQuerySuccess(statusCode) {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        loading: true,
      };
    });
    getAllClients(setTableData, onQueryError, userToken);
  }

  function onQuerySuccessLog(statusCode) {
    addAppLog(appLog)
    setTableState((prevValue) => {
      return {
        ...prevValue,
        loading: true,
      };
    });
    getAllClients(setTableData, onQueryError, userToken);
  }

  function onQueryError(error) {
    let statusCode = parseInt(error.message);
    if (statusCode === 403) {
      setForbiddenQuery(true);
    } else if (statusCode === 401) {
      //Here goes some modal about unauthorized operation
    } else {
      setErrorStatus(true);
    }
  }

  function setTableData(fetchedData) {
    setTableState({ data: fetchedData, loading: false });
  }

  const columns = [
    {
      name: "client_name",
      label: "Nombre",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleLarge,
      },
    },
    {
      name: "client_code",
      label: "RIF",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleSmall,
      },
    },
    {
      name: "type",
      label: "Tipo",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleType,
        filterType: "multiselect",
        filterOptions: {
          names: Object.values(typeMap),
          logic(type, filterVal) {
            return !(filterVal.includes(typeMap[type]) || false)
          }
        }
      },
    },

    {
      name: "owner_representative_name",
      label: "Vendedor asignado",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleMedium,
      },
    },
    {
      name: "capturing_representative_name",
      label: "Capturado por",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleMedium,
      },
    },
    {
      name: "order_frequency",
      label: "Frecuencia compra",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleOrderFrequency,
        filterType: "multiselect",
        filterOptions: {
          names: Object.values(orderFrequencyMap),
          logic(order_frequency, filterVal) {
            return !(filterVal.includes(orderFrequencyMap[order_frequency]) || false)
          }
        }
      },
    },
    {
      name: "status",
      label: "Estado",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleStatus,
        filterType: "multiselect",
        filterOptions: {
          names: Object.values(statusMap),
          logic(status, filterVal) {
            return !(filterVal.includes(statusMap[status]) || false)
          },
        }
      },
    },
  ];

  const options = {
    ...MUIDataTableSpanish,
    selectableRows: "none",
    filterType: "textField",
    filter: true,
    sort: true,
    download: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    onRowClick: handleRowClick,
    onRowsDelete: false,
    // sortOrder: {
    //   name: 'client_code',
    //   direction: 'asc'
    // },
    setRowProps: (row, dataIndex) => ({
      onDoubleClick: () => {
        handleDoubleClick(row, dataIndex)
      },
      onMouseOver: () => {
        handleMouseOver(row, dataIndex)
      }
    })
  };

  return (
    <div>
      <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
        <MUIDataTable
          title={"Lista de Clientes"}
          data={tableState.data}
          columns={columns}
          options={options}
        />
        <Menu
            open={contextMenu !== null}
            onClose={handleContextClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            <MenuList>
              <MenuItem onClick={handleContextSee}>Ver/Editar</MenuItem>
              <MenuItem 
                onClick={handleContextDeletePrompt}
                disabled={isDeleteDisabled}
                >
                  Borrar
              </MenuItem>
            </MenuList>
        </Menu>
      </div>
      <Can 
          role={userPrivilege} 
          perform="clients:edit"
          data={[]} 
          yes={() => {
            setIsEditDisabled(false);
            return null
          }}
          no={() => {
            setIsEditDisabled(true);
            return null
          }}
      />
      <Can 
          role={userPrivilege} 
          perform="clients:delete"
          data={[]} 
          yes={() => {
            setIsDeleteDisabled(false);
            return null
          }}
          no={() => {
            setIsDeleteDisabled(true);
            return null
          }}
      />
      <Button
        disabled={isEditDisabled}
        variant="contained"
        size="large"
        color="primary"
        onClick={handleClickOpen}
        className={classes.button}
      >
        Agregar
      </Button>
      <ClientModal 
        onAdd={addClient}
        onEdit={editClient}
        representatives={representativeList}  
        editedClient={editedClient}
        isOpen={open}
        setIsOpen={setOpen}
        isSee={contextSee}
        setIsSee={setContextSee}
        isEditDisabled={isEditDisabled}
        setDidStatusChange={setDidStatusChange}
      />
      <ConfirmationModal 
        isOpen={confirmationModalOpen}
        setIsOpen={setConfirmationModalOpen}
        onSuccess={handleContextDelete}
      />
      {forbiddenQuery && <ErrorModal setOpen={setForbiddenQuery} />}
    </div>
  );
};

export default ClientTable;

//https://www.smashingmagazine.com/2020/06/rest-api-react-fetch-axios/
//https://itnext.io/centralizing-api-error-handling-in-react-apps-810b2be1d39d
