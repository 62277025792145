/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { createContext, useState } from "react";
import ReactDOM from "react-dom";
import App from "app";
//Context
import GeneralContextProvider from "components/GeneralContextProvider/GeneralContextProvider";

ReactDOM.render(
  <GeneralContextProvider>
    <App />
  </GeneralContextProvider>,
  document.getElementById("root")
);

// ReactDOM.render(
//     <Router history={hist}>
//       <Switch>
//         <Route path="/login" component={LoginPage} />
//         <Route path="/admin" component={Admin} />
//         <Route path="/error" component={ErrorNotFound} />
//         <Redirect from="/" to="/admin/dashboard" />
//       </Switch>
//     </Router>,
//   document.getElementById("root")
// );
