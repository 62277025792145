import React, { useEffect, useState, useContext } from "react";
import MUIDataTable from "mui-datatables";
import { Menu, MenuItem, MenuList } from '@mui/material'; 
import MUIDataTableSpanish from "variables/MUIDataTablesSpanish";
import RepresentativeModal from "components/RepresentativeModal/RepresentativeModal";
import { makeStyles } from "@material-ui/core/styles";
import ErrorModal from "components/ErrorModal/ErrorModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {
  customCellStyleSmall,
  customCellStyleMedium,
  customCellStyleLarge,
  customCellStyleStatus,
} from "variables/cellStyles";
//Context
import { RepresentativeTableContext, UpdateRepresentativeTableContext, UpdateErrorStatusContext, UserContext, UserPrivilegeContext, UserNameContext } from "components/GeneralContextProvider/GeneralContextProvider"
import {
  getAllRepresentatives,
  createRepresentative, 
  updateRepresentative,
  removeRepresentative, 
} from "repositories/RepresentativeRepository";
import Button from "@material-ui/core/Button";
import { statusMap, representativeDefault } from "variables/general"
//RBAC
import Can from "components/Can/Can"


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const RepresentativeTable = () => {

  const userName = useContext(UserNameContext);
  const userPrivilege = useContext(UserPrivilegeContext);

  const [contextSee, setContextSee] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);

  const [open, setOpen] = useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const [editedRepresentative, setEditedRepresentative] = useState(representativeDefault);
  const [forbiddenQuery, setForbiddenQuery] = useState(false);

  const tableState = useContext(RepresentativeTableContext);
  const setTableState = useContext(UpdateRepresentativeTableContext);
  const setErrorStatus = useContext(UpdateErrorStatusContext);
  const userToken = useContext(UserContext);

  const classes = useStyles();

  useEffect(() => {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        loading: true,
      };
    });
    getAllRepresentatives(setTableData, onQueryError, userToken);
  }, []);

  function addRepresentative(representative) {
    createRepresentative(
      representative,
      onQuerySuccess,
      onQueryError,
      userToken
    );
  }

  function editRepresentative(representative) {
    updateRepresentative(
      representative,
      onQuerySuccess,
      onQueryError,
      userToken
    );
  }

  function deleteRepresentative(representative) {
    let representativeId = representative.id;
      removeRepresentative(
        representativeId,
        onQuerySuccess,
        onQueryError,
        userToken
      );
  }

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : 
          null,
    );
    
  };

  const handleContextClose = () => {
    setContextMenu(null);
  };

  const handleContextSee = () => {
    setContextSee(true)
    setOpen(true)
    setContextMenu(null);
  };

  const handleContextDeletePrompt = () => {
    setContextMenu(null)
    setConfirmationModalOpen(true)
  };

  const handleContextDelete = () => {
    if (editedRepresentative) {
      deleteRepresentative(editedRepresentative)
      setEditedRepresentative(representativeDefault)
    }
    setContextMenu(null);
  };

  const handleClickOpen = () => {
    setEditedRepresentative(representativeDefault)
    setOpen(true);
  };

  function handleRowClick(rowData, rowMeta) {
    const representative = tableState.data[rowMeta.dataIndex];
    setEditedRepresentative(representative);
  }

  const handleDoubleClick = (row, dataIndex) => {
    const representative = tableState.data[dataIndex]
    handleContextSee()
  }

  const handleMouseOver = (row, dataIndex) => {
    const representative = tableState.data[dataIndex]
    setEditedRepresentative(representative)
  }

  function onQuerySuccess(statusCode) {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        loading: true,
      };
    });
    getAllRepresentatives(setTableData, onQueryError, userToken);
  }

  function onQueryError(error) {
    let statusCode = parseInt(error.message);
    if (statusCode===403) {
      setForbiddenQuery(true);
    } else if (statusCode===401) {
      //Here goes some modal about unauthorized operation
    } else {
      setErrorStatus(true);
    }
  }

  function setTableData(fetchedData) {
    setTableState({ data: fetchedData, loading: false });
  }

  const columns = [
    {
      name: "fname",
      label: "Nombre",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleSmall,
      },
    },
    {
      name: "lname",
      label: "Apellido",
      options: {
        sortOrder: 'desc',
        filter: true,
        sort: true,
        customBodyRender: customCellStyleSmall,
      },
    },
    {
      name: "contact_info",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleLarge,
      },
    },
    {
      name: "note",
      label: "Nota",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleLarge,
      },
    },
    {
      name: "status",
      label: "Estado",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleStatus,
        filterType: "multiselect",
        filterOptions: {
          names: Object.values(statusMap),
          logic(status, filterVal) {
            return !(filterVal.includes(statusMap[status]) || false)
          },
        }
      },
    },
  ];

  const options = {
    ...MUIDataTableSpanish,
    selectableRows: "none",
    filterType: "textField",
    filter: true,
    sort: true,
    download: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    onRowClick: handleRowClick,
    onRowsDelete: false,
    // sortOrder: {
    //   name: 'fname',
    //   direction: 'asc'
    // },
    setRowProps: (row, dataIndex) => ({
      onDoubleClick: () => {
        handleDoubleClick(row, dataIndex)
      },
      onMouseOver: () => {
        handleMouseOver(row, dataIndex)
      }
    })
  };

  return (
    <div>
      <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
        <MUIDataTable
          title={"Lista de Vendedores"}
          data={tableState.data}
          columns={columns}
          options={options}
        />
       <Menu
            open={contextMenu !== null}
            onClose={handleContextClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            <MenuList>
              <MenuItem onClick={handleContextSee}>Ver/Editar</MenuItem>
              <MenuItem 
                onClick={handleContextDeletePrompt}
                disabled={isDeleteDisabled}
                >
                  Borrar
              </MenuItem>
            </MenuList>
        </Menu>
      </div>
      <Can 
          role={userPrivilege} 
          perform="representatives:edit"
          data={[]} 
          yes={() => {
            setIsEditDisabled(false);
            return null
          }}
          no={() => {
            setIsEditDisabled(true);
            return null
          }}
      />
      <Can 
          role={userPrivilege} 
          perform="representatives:delete"
          data={[]} 
          yes={() => {
            setIsDeleteDisabled(false);
            return null
          }}
          no={() => {
            setIsDeleteDisabled(true);
            return null
          }}
      />
      <Button
        disabled={isEditDisabled}
        variant="contained"
        size="large"
        color="primary"
        onClick={handleClickOpen}
        className={classes.margin}
      >
        Agregar
      </Button>
      <RepresentativeModal 
        onAdd={addRepresentative}
        onEdit={editRepresentative}
        editedRepresentative={editedRepresentative}
        isOpen={open}
        setIsOpen={setOpen}
        isSee={contextSee}
        setIsSee={setContextSee}
        isEditDisabled={isEditDisabled}
      />
      <ConfirmationModal 
        isOpen={confirmationModalOpen}
        setIsOpen={setConfirmationModalOpen}
        onSuccess={handleContextDelete}
      />
      {forbiddenQuery && <ErrorModal setOpen={setForbiddenQuery} />}
    </div>
  );
};

export default RepresentativeTable;

//https://www.smashingmagazine.com/2020/06/rest-api-react-fetch-axios/
//https://itnext.io/centralizing-api-error-handling-in-react-apps-810b2be1d39d
