import { baseUrl } from "variables/general.js"

const getAllClients = (setTableData, onError, token) => {
  const queryUrl = baseUrl + "Clients";

  fetch(queryUrl, {
    method: "GET",
    headers: {
      "Authorization": [token],
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((res) => res.json())
    .then((fetchedData) => {
      setTableData(fetchedData);
    })
    .catch((err) => {
      onError(err);
    });
};

const getClientsByOwner = (setTableData, ownerEmail, onError, token) => {
  const params = {
    ownerRepresentative: 1,
    ownerRepresentativeEmail: ownerEmail,
  };
  let queryUrl = new URL(baseUrl + "Clients");
  queryUrl.search = new URLSearchParams(params).toString();

  fetch(queryUrl, {
    method: "GET",
    headers: {
      "Authorization": [token],
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((res) => res.json())
    .then((fetchedData) => {
      setTableData(fetchedData);
    })
    .catch((err) => {
      onError(err);
    });
};

const getLastClientActivities = (clientCode, setTableData, onError, token) => {
  let today = new Date();
  const params = { 
    lastActivities: 1, 
    thresholdDate: today.toLocaleDateString("fr-CA"),
    code: clientCode};
  let queryUrl = new URL(baseUrl + "Clients");
  queryUrl.search = new URLSearchParams(params).toString();

  fetch(queryUrl, {
    method: "GET",
    headers: {
      "Authorization": [token],
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((res) => res.json())
    .then((fetchedData) => {
      setTableData(fetchedData);
    })
    .catch((err) => {
      onError(err);
    });
};

const getNextClientActivities = (clientCode, setTableData, onError, token) => {
  let today = new Date();
  const params = { 
    nextActivities: 1, 
    thresholdDate: today.toLocaleDateString("fr-CA"),
    code: clientCode};
  let queryUrl = new URL(baseUrl + "Clients");
  queryUrl.search = new URLSearchParams(params).toString();

  fetch(queryUrl, {
    method: "GET",
    headers: {
      "Authorization": [token],
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((res) => res.json())
    .then((fetchedData) => {
      setTableData(fetchedData);
    })
    .catch((err) => {
      onError(err);
    });
};


const createClient = (body, onSuccess, onError, token) => {
  const queryUrl = baseUrl + "Clients";
  // creates entity
  fetch(queryUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      "Authorization": token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((response) => {
      onSuccess(response.status);
    })
    .catch((err) => {
      onError(err);
    });
};

const updateClient = (body, onSuccess, onError, token) => {
  // this will update entries with PUT
  const queryUrl = baseUrl + "Clients";
  fetch(queryUrl, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      "Authorization": token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((response) => {
      onSuccess(response.status);
    })
    .catch((err) => {
      onError(err);
    });
};

const removeClient = (clientCode, onSuccess, onError, token) => {
  const idParam = { code: clientCode };
  var queryUrl = new URL(baseUrl + "Clients");
  queryUrl.search = new URLSearchParams(idParam).toString();
  // deletes entities
  fetch(queryUrl, {
    method: "DELETE",
    headers: {
      "Authorization": token,
    }
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((response) => {
      onSuccess(response.status);
    })
    .catch((err) => {
      onError(err);
    });
};

export { getAllClients, getClientsByOwner, getLastClientActivities, getNextClientActivities, createClient, updateClient, removeClient };
