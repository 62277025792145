import React, { useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import { isEmpty } from "validator";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import spaLocale from "date-fns/locale/es";
import {
  MuiPickersUtilsProvider,
  Calendar,
  KeyboardDatePicker,
} from "@material-ui/pickers";
//Context
import { UserPrivilegeContext } from "components/GeneralContextProvider/GeneralContextProvider";
//RBAC
import Can from "components/Can/Can";
//General variables
import { contactDefault } from "variables/general";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function ContactDialog(props) {
  const { onAdd, onEdit, clients, isOpen, setIsOpen, isSee, setIsSee, isEditDisabled, editedContact } = props;

  const classes = useStyles();
  //Privilege level of the current user
  const userPrivilege = useContext(UserPrivilegeContext);
  //These two are for the Autocomplete component
  const [clientAutocompleteValue, setClientAutocompleteValue] = useState({});
  const [clientAutocompleteInput, setClientAutocompleteInput] = useState("");

  const [contact, setContact] = useState(contactDefault);
  const [contactError, setContactError] = useState({
    fname: false,
    lname: false,
    client_name: false,
  });

  useEffect(() => {
    if(editedContact.fname) {
      setContact(editedContact);
    } else {
      resetFields()
    }
    // const editedContactClient = clients.filter(
    //   (client) => client.client_code === editedContact.client_code)[0];
    setClientAutocompleteValue(prevState => {
      return {
        ...prevState,
        client_code: editedContact.client_code,
        client_name: editedContact.client_name
      }
    });
  }, [editedContact]);

  function handleChange(event) {
    const { value, name } = event.target;

    if (name === "fname") {
      setContact((prevState) => {
        return {
          ...prevState,
          fname: value,
        };
      });
    }

    if (name === "lname") {
      setContact((prevState) => {
        return {
          ...prevState,
          lname: value,
        };
      });
    }

    if (name === "email") {
      setContact((prevState) => {
        return {
          ...prevState,
          email: value,
        };
      });
    }

    if (name === "phone_number") {
      setContact((prevState) => {
        return {
          ...prevState,
          phone_number: value,
        };
      });
    }

    if (name === "birthday") {
      setContact((prevState) => {
        return {
          ...prevState,
          note: value,
        };
      });
    }

    if (name === "client_name") {
      setContact((prevState) => {
        return {
          ...prevState,
          client_name: value,
        };
      });
    }

    validateField(!isEmpty(value.toString()), name);
  }

  const validateField = (validator, fieldName) => {
    if (validator) {
      setContactError((prevState) => {
        return { ...prevState, [fieldName]: false };
      });
      return true;
    } else {
      setContactError((prevState) => {
        return { ...prevState, [fieldName]: true };
      });
      return false;
    }
  };

  function validateAll() {
    const check1 = validateField(!isEmpty(contact.fname), "fname");
    const check2 = validateField(!isEmpty(contact.lname), "lname");
    const check3 = validateField(!isEmpty(contact.client_name), "client_name");
    return check1 && check2 && check3;
  }

  function resetFields() {
    setContact(contactDefault);
    setContactError({
      fname: false,
      lname: false,
      client_name: false,
    });
  }

  const handleClose = () => {
    resetFields();
    setIsOpen(false);
    setIsSee(false);
  };

  const handleClickSave = () => {
    if (!validateAll()) {
      return;
    }
    if (isSee) {
      onEdit(contact)
    } else {
      onAdd(contact);
    }
    resetFields();
    setIsOpen(false);
    setIsSee(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle>Contacto</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <div>
              <TextField
                error={contactError.fname}
                disabled={isEditDisabled}
                onChange={handleChange}
                value={contact.fname}
                name="fname"
                label="Nombre"
                style={{ margin: 8 }}
                placeholder=""
                helperText={
                  contactError.fname && "Este campo no puede estar vacio"
                }
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                error={contactError.lname}
                disabled={isEditDisabled}
                onChange={handleChange}
                value={contact.lname}
                name="lname"
                label="Apellido"
                style={{ margin: 8 }}
                placeholder=""
                helperText={
                  contactError.lname && "Este campo no puede estar vacio"
                }
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                disabled={isEditDisabled}
                onChange={handleChange}
                value={contact.email}
                name="email"
                label="Email"
                style={{ margin: 8 }}
                placeholder=""
                helperText=""
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                disabled={isEditDisabled}
                onChange={handleChange}
                value={contact.phone_number}
                name="phone_number"
                label="Número de contacto"
                style={{ margin: 8 }}
                placeholder=""
                helperText=""
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={spaLocale}>
                <KeyboardDatePicker
                  disabled={isEditDisabled}
                  style={{ margin: 16, marginLeft: 8 }}
                  label="Fecha de cumpleaños"
                  value={contact.birthday}
                  onChange={(date) =>
                    setContact((prevState) => {
                      return {
                        ...prevState,
                        birthday: date,
                      };
                    })
                  }
                  format="dd MMMM"
                />
              </MuiPickersUtilsProvider>
              <Autocomplete
                disabled={isEditDisabled}
                value={clientAutocompleteValue || ""}
                onChange={(event, newClient) => {
                  setClientAutocompleteValue(newClient);
                  if (newClient) {
                    setContact((prevState) => {
                      return {
                        ...prevState,
                        client_code: newClient.client_code,
                        client_name: newClient.client_name,
                      };
                    });
                  }
                }}
                inputValue={clientAutocompleteInput}
                onInputChange={(event, input) => {
                  setClientAutocompleteInput(input);
                }}
                options={clients}
                getOptionLabel={(client) => client.client_name || ""}
                getOptionSelected={(option, value) => option.id === value.id}
                name="client_name"
                label="Cliente asociado"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cliente"
                    margin="normal"
                    style={{ margin: 8 }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              <TextField
                disabled
                error={contactError.client_name}
                onChange={handleChange}
                value={contact.client_code}
                name="client_code"
                label="RIF"
                style={{ margin: 8 }}
                placeholder=""
                helperText={
                    contactError.client_name && "Este campo no puede estar vacio"
                }
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClickSave} disabled={isEditDisabled} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

//https://dev.to/alejluperon/react-form-validation-39bi
