import React, { useEffect, useState, useContext } from "react";
import MUIDataTable from "mui-datatables";
import MUIDataTableSpanish from "variables/MUIDataTablesSpanish";
import AppLogForm from "components/AppLogForm/AppLogForm";
import ErrorModal from "components/ErrorModal/ErrorModal";
import {
  customCellStyleSmall,
  customCellStyleLarge,
  customCellStyleExtraLarge,
  customCellStyleMedium,
  customCellStyleDate,
  customCellStyleStatus,
} from "variables/cellStyles";
//Context
import { AppLogTableContext, UpdateAppLogTableContext, UpdateErrorStatusContext, UserContext } from "components/GeneralContextProvider/GeneralContextProvider"
  import { 
      getAllAppLogs,
    } from "repositories/AppLogRepository";

const AppLogTable = () => {
  const [selectedLog, setSelectedLog] = useState({});
  const [forbiddenQuery, setForbiddenQuery] = useState(false);

  const tableState = useContext(AppLogTableContext);
  const setTableState = useContext(UpdateAppLogTableContext);
  const setErrorStatus = useContext(UpdateErrorStatusContext);
  const userToken = useContext(UserContext);

  useEffect(() => {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        loading: true,
      };
    });
    getAllAppLogs(setTableData, onQueryError, userToken);
  }, []);

  function handleRowClick(rowData, rowMeta) {
    const log = tableState.data[rowMeta.dataIndex];
    setSelectedLog(log);
  }

  function onQueryError(error) {
    let statusCode = parseInt(error.message);
    if (statusCode===403) {
      setForbiddenQuery(true);
    } else if (statusCode===401) {
      //Here goes some modal about unauthorized operation
    } else {
      setErrorStatus(true);
    }
  }

  function setTableData(fetchedData) {
    setTableState({ data: fetchedData, loading: false });
  }

  const columnsAppUsers = [
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleLarge,
      },
    },
    {
      name: "privilegeLevel",
      label: "Nivel de privilegio",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleSmall,
      },
    },
    {
      name: "note",
      label: "Nota",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleLarge,
      },
    },
  ];

  const columnsAppLogs = [
    {
      name: "log_type",
      label: "Tipo",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleLarge,
      },
    },
    {
      name: "log_description",
      label: "Descripción",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleExtraLarge,
      },
    },
    {
      name: "log_date",
      label: "Fecha",
      options: {
        sortOrder: "desc",
        filter: true,
        sort: true,
        customBodyRender: customCellStyleDate,
      },
    },
  ];

  const optionsAppLogs = {
    ...MUIDataTableSpanish,
    selectableRows: "none",
    filterType: "textField",
    filter: true,
    download: false,
    sort: true,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    onRowClick: handleRowClick,
    onRowsDelete: false,
  };

  const optionsAppUsers = {
    ...MUIDataTableSpanish,
    selectableRows: "none",
    filterType: "textField",
    filter: true,
    download: false,
    sort: true,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    onRowClick: handleRowClick,
    onRowsDelete: false,
  };

  return (
    <div>
      <MUIDataTable
        title={"Logs de la Aplicación"}
        data={tableState.data}
        columns={columnsAppLogs}
        options={optionsAppLogs}
      />
      <AppLogForm selectedLog={selectedLog} />
      {forbiddenQuery && <ErrorModal setOpen={setForbiddenQuery} />}
    </div>
  );
};

export default AppLogTable;

//https://www.smashingmagazine.com/2020/06/rest-api-react-fetch-axios/
//https://itnext.io/centralizing-api-error-handling-in-react-apps-810b2be1d39d
