import React from "react";
//General variables
import { privilegeMap, executedMap, isOrderMap, statusMap, typeMap, orderFrequencyMap} from "variables/general"

const customCellStyleExtraLarge = (val) => {
  let parentStyle = {
    position: "relative",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "700px",
  };
  let cellStyle = {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  return (
    <div style={parentStyle}>
      <div style={cellStyle}>{val}</div>
    </div>
  );
};

const customCellStyleLarge = (val) => {
  let parentStyle = {
    position: "relative",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "250px",
  };
  let cellStyle = {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  return (
    <div style={parentStyle}>
      <div style={cellStyle}>{val}</div>
    </div>
  );
};

const customCellStyleMedium = (val) => {
  let parentStyle = {
    position: "relative",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "150px",
  };
  let cellStyle = {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  return (
    <div style={parentStyle}>
      <div style={cellStyle}>{val}</div>
    </div>
  );
};

const customCellStyleSmall = (val) => {
  let parentStyle = {
    position: "relative",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "100px",
  };
  let cellStyle = {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  return (
    <div style={parentStyle}>
      <div style={cellStyle}>{val}</div>
    </div>
  );
};

const customCellStyleDate = (val) => {
  let parentStyle = {
    position: "relative",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "100px",
  };
  let cellStyle = {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  return (
    <div style={parentStyle}>
      <div style={cellStyle}>
      {val ? new Date(val).toLocaleDateString('spa', {
           year: 'numeric',
           month: 'short',
           day: 'numeric'
         })
         :
        "No aplica"}
      </div>
    </div>
  );
};

const customCellStylePrivilege = (val) => {
  val = Number(val);
  let parentStyle = {
    position: "relative",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "150px",
  };
  let cellStyle = {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  return (
    <div style={parentStyle}>
      <div style={cellStyle}>{privilegeMap[val] || ""}</div>
    </div>
  );
};

const customCellStyleStatus = (val) => {
  val = Number(val);
  let parentStyle = {
    position: "relative",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "100px",
  };
  let cellStyle = {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  return (
    <div style={parentStyle}>
      <div style={cellStyle}>
        {statusMap[val] || ""}
      </div>
    </div>
  );
};

const customCellStyleType = (val) => {
  val = Number(val);
  let parentStyle = {
    position: "relative",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "100px",
  };
  let cellStyle = {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  return (
    <div style={parentStyle}>
      <div style={cellStyle}>
        {typeMap[val] || ""}
      </div>
    </div>
  );
};

const customCellStyleIsOrder = (val) => {
  val = Number(val);
  let parentStyle = {
    position: "relative",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "150px",
  };
  let cellStyle = {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  return (
    <div style={parentStyle}>
      <div style={cellStyle}>{isOrderMap[val] || ""}</div>
    </div>
  );
};

const customCellStyleExecuted = (val) => {
  val = Number(val);
  let parentStyle = {
    position: "relative",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "150px",
  };
  let cellStyle = {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  return (
    <div style={parentStyle}>
      <div style={cellStyle}>{executedMap[val] || ""}</div>
    </div>
  );
};

const customCellStyleOrderFrequency = (val) => {
  val = Number(val);
  let parentStyle = {
    position: "relative",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "150px",
  };
  let cellStyle = {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  return (
    <div style={parentStyle}>
      <div style={cellStyle}>{orderFrequencyMap[val] || ""}</div>
    </div>
  );
};


export {
  customCellStyleSmall,
  customCellStyleMedium,
  customCellStyleLarge,
  customCellStyleExtraLarge,
  customCellStylePrivilege,
  customCellStyleStatus,
  customCellStyleType,
  customCellStyleIsOrder,
  customCellStyleExecuted,
  customCellStyleDate,
  customCellStyleOrderFrequency
};
