import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { LiveTvRounded } from '@material-ui/icons';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Box p={3}>
        <Box component="div">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: "10px"
  },
}));

export default function ScrollableTabPanel(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
 
  const { onViewChange } = props;

  const handleChange = (event, newValue) => {
    onViewChange(newValue);
    setValue(newValue);
  };

  const month = new Array();
  month[0] = "Enero";
  month[1] = "Febrero";
  month[2] = "Marzo";
  month[3] = "Abril";
  month[4] = "Mayo";
  month[5] = "Junio";
  month[6] = "Julio";
  month[7] = "Agosto";
  month[8] = "Septiembre";
  month[9] = "Octubre";
  month[10] = "Noviembre";
  month[11] = "Diciembre";

  const currentMonth = new Date().getMonth();
  const nextMonth = new Date().getMonth()+1;
  const currentMonthWord = month[currentMonth];
  const nextMonthWord = month[nextMonth];

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {/* <Tab label="Hoy" {...a11yProps(0)} />
          <Tab label="Mañana" {...a11yProps(1)} />
          <Tab label="Esta semana" {...a11yProps(2)} />
          <Tab label="Próxima semana" {...a11yProps(3)} />
          <Tab label="Rezagados" {...a11yProps(4)} />
          <Tab label="Todas" {...a11yProps(5)} /> */}

          <Tab label="Hoy" />
          <Tab label="Mañana" />
          <Tab label="Esta semana" />
          <Tab label="Próxima semana" />
          <Tab label={currentMonthWord} />
          <Tab label={nextMonthWord} />
          <Tab label="Rezagados" />
          <Tab label="Todas" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {props.table0}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {props.table1}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {props.table2}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {props.table3}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {props.table4}
      </TabPanel>
      <TabPanel value={value} index={5}>
        {props.table5}
      </TabPanel>
      <TabPanel value={value} index={6}>
        {props.table6}
      </TabPanel>
      <TabPanel value={value} index={7}>
        {props.table7}
      </TabPanel>
    </div>
  );
}
