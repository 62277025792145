const tableOptions = {
textLabels: {
    body: {
      noMatch: "No se encontró información",
      toolTip: "Organizar",
      columnHeaderTooltip: column => `Sort for ${column.label}`
    },
    pagination: {
      next: "Siguiente Página",
      previous: "Página Anterior",
      rowsPerPage: "Filas por Página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Ver Columnas",
      filterTable: "Filtrar Tablas",
    },
    filter: {
      all: "Todos",
      title: "FILTROS",
      reset: "RESET",
    },
    viewColumns: {
      title: "Mostrar Columnas",
      titleAria: "Mostrar/Ocultar Columnas",
    },
    selectedRows: {
      text: "Filas Seleccionadas",
      delete: "Borrar",
      deleteAria: "Borrar Filas Seleccionadas",
    },
  }
}

export default tableOptions;