import React, { useEffect, useState, useContext } from "react";
import MUIDataTable from "mui-datatables";
import MUIDataTableSpanish from "variables/MUIDataTablesSpanish";
import ContactModal from "components/ContactModal/ContactModal";
import ErrorModal from "components/ErrorModal/ErrorModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { makeStyles } from "@material-ui/core/styles";
import {
  customCellStyleSmall,
  customCellStyleMedium,
  customCellStyleLarge,
  customCellStyleStatus,
} from "variables/cellStyles";
//Context
import { ContactTableContext, UpdateContactTableContext, UpdateErrorStatusContext, UserContext, UserPrivilegeContext } from "components/GeneralContextProvider/GeneralContextProvider"
import {
  getAllContacts,
  createContact,
  updateContact,
  removeContact,
} from "repositories/ContactRepository";
import {
    getAllClients,
  } from "repositories/ClientRepository";
import { Menu, MenuItem, MenuList } from '@mui/material'; 
import Button from "@material-ui/core/Button";
//RBAC
import Can from "components/Can/Can"
import { contactDefault } from "variables/general";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  button: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ContactTable = () => {

  const classes = useStyles();

  const [contextSee, setContextSee] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);

  const [open, setOpen] = useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const [editedContact, setEditedContact] = useState(contactDefault);
 
  const [forbiddenQuery, setForbiddenQuery] = useState(false);
  const [clientList, setClientList] = useState([]);

  const userPrivilege = useContext(UserPrivilegeContext);

  const tableState = useContext(ContactTableContext);
  const setTableState = useContext(UpdateContactTableContext);
  const setErrorStatus = useContext(UpdateErrorStatusContext);
  const userToken = useContext(UserContext);

  useEffect(() => {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        loading: true,
      };
    });
    getAllContacts(setTableData, onQueryError, userToken);
    getAllClients(setClientList, onQueryError, userToken);
  }, []);

  function addContact(contact) {
    createContact(
      contact,
      onQuerySuccess,
      onQueryError,
      userToken
    );
  }

  function editContact(contact) {
    updateContact(
      contact,
      onQuerySuccess,
      onQueryError,
      userToken
    );
  }

  function deleteContact(contact) {
    let contactId = contact.id;
      removeContact(
        contactId,
        onQuerySuccess,
        onQueryError,
        userToken
      );
  }

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : 
          null,
    );
    
  };

  const handleContextClose = () => {
    setContextMenu(null);
  };

  const handleContextSee = () => {
    setContextSee(true)
    setOpen(true)
    setContextMenu(null);
  };

  const handleContextDeletePrompt = () => {
    setContextMenu(null)
    setConfirmationModalOpen(true)
  };

  const handleContextDelete = () => {
    if (editedContact) {
      deleteContact(editedContact)
      setEditedContact(contactDefault)
    }
    setContextMenu(null);
  };

  const handleClickOpen = () => {
    setEditedContact(contactDefault)
    setOpen(true);
  };

  function handleRowClick(rowData, rowMeta) {
    const contact = tableState.data[rowMeta.dataIndex];
    setEditedContact(contact);
  }

  const handleDoubleClick = (row, dataIndex) => {
    handleContextSee()
  }

  const handleMouseOver = (row, dataIndex) => {
    const contact = tableState.data[dataIndex]
    setEditedContact(contact)
  }

  function onQuerySuccess(statusCode) {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        loading: true,
      };
    });
    getAllContacts(setTableData, onQueryError, userToken);
  }

  function onQueryError(error) {
    let statusCode = parseInt(error.message);
    if (statusCode===403) {
      setForbiddenQuery(true);
    } else if (statusCode===401) {
      //Here goes some modal about unauthorized operation
    } else {
      setErrorStatus(true);
    }
  }

  function setTableData(fetchedData) {
    setTableState({ data: fetchedData, loading: false });
  }

  const columns = [
    {
      name: "fname",
      label: "Nombre",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleSmall,
      },
    },
    {
      name: "lname",
      label: "Apellido",
      options: {
        sortOrder: 'desc',
        filter: true,
        sort: true,
        customBodyRender: customCellStyleSmall,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleLarge,
      },
    },
    {
      name: "phone_number",
      label: "Número de contacto",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleLarge,
      },
    },
    {
      name: "client_name",
      label: "Cliente asociado",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleLarge,
      },
    },
  ];

  const options = {
    ...MUIDataTableSpanish,
    selectableRows: "none",
    filterType: "textField",
    filter: true,
    sort: true,
    download: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    onRowClick: handleRowClick,
    onRowsDelete: false,
    // sortOrder: {
    //   name: 'fname',
    //   direction: 'asc'
    // },
    setRowProps: (row, dataIndex) => ({
      onDoubleClick: () => {
        handleDoubleClick(row, dataIndex)
      },
      onMouseOver: () => {
        handleMouseOver(row, dataIndex)
      }
    })
  };

  return (
    <div>
      <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
        <MUIDataTable
          title={"Lista de Contactos"}
          data={tableState.data}
          columns={columns}
          options={options}
        />
        <Menu
          open={contextMenu !== null}
          onClose={handleContextClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuList>
            <MenuItem onClick={handleContextSee}>Ver/Editar</MenuItem>
            <MenuItem 
              onClick={handleContextDeletePrompt}
              disabled={isDeleteDisabled}
              >
                Borrar
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
      <Can 
          role={userPrivilege} 
          perform="contacts:edit"
          data={[]} 
          yes={() => {
            setIsEditDisabled(false);
            return null
          }}
          no={() => {
            setIsEditDisabled(true);
            return null
          }}
      />
      <Can 
          role={userPrivilege} 
          perform="contacts:delete"
          data={[]} 
          yes={() => {
            setIsDeleteDisabled(false);
            return null
          }}
          no={() => {
            setIsDeleteDisabled(true);
            return null
          }}
      />
      
      <Button
        disabled={isEditDisabled}
        variant="contained"
        size="large"
        color="primary"
        onClick={handleClickOpen}
        className={classes.button}
      >
        Agregar
      </Button>
      <ContactModal
        onAdd={addContact}
        onEdit={editContact}
        clients={clientList}
        isOpen={open}
        setIsOpen={setOpen}
        isSee={contextSee}
        setIsSee={setContextSee}
        isEditDisabled={isEditDisabled}
        editedContact={editedContact}
      />
      <ConfirmationModal 
        isOpen={confirmationModalOpen}
        setIsOpen={setConfirmationModalOpen}
        onSuccess={handleContextDelete}
      />
      {forbiddenQuery && <ErrorModal setOpen={setForbiddenQuery} />}
    </div>
  );
};

export default ContactTable;

//https://www.smashingmagazine.com/2020/06/rest-api-react-fetch-axios/
//https://itnext.io/centralizing-api-error-handling-in-react-apps-810b2be1d39d
