import React, { useState, createContext } from "react";

export const ErrorStatusContext = createContext();
export const UpdateErrorStatusContext = createContext();

export const AppLogTableContext = createContext();
export const UpdateAppLogTableContext = createContext();
export const ActivityTableContext = createContext();
export const UpdateActivityTableContext = createContext();
export const ClientTableContext = createContext();
export const UpdateClientTableContext = createContext();
export const RepresentativeTableContext = createContext();
export const UpdateRepresentativeTableContext = createContext();
export const DashboardContext = createContext();
export const UpdateDashboardContext = createContext();
export const AppUserTableContext = createContext();
export const UpdateAppUserTableContext = createContext();
export const ContactTableContext = createContext();
export const UpdateContactTableContext = createContext();
export const ConfigVariableContext = createContext();
export const UpdateConfigVariableContext = createContext();

export const UserContext = createContext();
export const UpdateUserContext = createContext();
export const UserNameContext = createContext();
export const UpdateUserNameContext = createContext();
export const UserEmailContext = createContext();
export const UpdateUserEmailContext = createContext();
export const UserImageContext = createContext();
export const UpdateUserImageContext = createContext();
export const UserPrivilegeContext = createContext();
export const UpdateUserPrivilegeContext = createContext();

export default function GeneralContextProvider({ children }) {
  const [userToken, setUserToken] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userImage, setUserImage] = useState("");
  const [userPrivilege, setUserPrivilege] = useState(-1);
  const [errorStatus, setErrorStatus] = useState(false);
  const [clientTableState, setClientTableState] = useState({
    loading: false,
    data: [],
  });
  const [configVariableState, setConfigVariableState] = useState({
    loading: false,
    data: [],
  });
  const [representativeTableState, setRepresentativeTableState] = useState({
    loading: false,
    data: [],
  });
  const [contactTableState, setContactTableState] = useState({
    loading: false,
    data: [],
  });
  const [appUserTableState, setAppUserTableState] = useState({
    loading: false,
    data: [],
  });
  const [appLogTableState, setAppLogTableState] = useState({
    loading: false,
    data: [],
  });
  const [ActivityTableState, setActivityTableState] = useState({
    loading: false,
    dataAll: [],
    dataToday: [],
    dataTomorrow: [],
    dataWeek: [],
    dataNextWeek: [],
    dataMonth: [],
    dataNextMonth: [],
    dataLagging: [],
    countAll: 0,
    countLagging: 0,
  });

  const [dashboardState, setDashboardState] = useState({
    countToday: 0,
    countWeek: 0,
    countLagging: 0,
    countActiveClients: 0,
    countInactiveClients: 0,
    unattendedClients: [],
  });

  return (
    <UserPrivilegeContext.Provider value={userPrivilege}>
      <UpdateUserPrivilegeContext.Provider value={setUserPrivilege}>
        <UserImageContext.Provider value={userImage}>
          <UpdateUserImageContext.Provider value={setUserImage}>
            <UserEmailContext.Provider value={userEmail}>
              <UpdateUserEmailContext.Provider value={setUserEmail}>
                <UserNameContext.Provider value={userName}>
                  <UpdateUserNameContext.Provider value={setUserName}>
                    <UserContext.Provider value={userToken}>
                      <UpdateUserContext.Provider value={setUserToken}>
                        <ErrorStatusContext.Provider value={errorStatus}>
                          <UpdateErrorStatusContext.Provider
                            value={setErrorStatus}
                          >
                            <DashboardContext.Provider value={dashboardState}>
                              <UpdateDashboardContext.Provider
                                value={setDashboardState}
                              >
                                <ActivityTableContext.Provider
                                  value={ActivityTableState}
                                >
                                  <UpdateActivityTableContext.Provider
                                    value={setActivityTableState}
                                  >
                                    <ClientTableContext.Provider
                                      value={clientTableState}
                                    >
                                      <UpdateClientTableContext.Provider
                                        value={setClientTableState}
                                      >
                                        <RepresentativeTableContext.Provider
                                          value={representativeTableState}
                                        >
                                          <UpdateRepresentativeTableContext.Provider
                                            value={setRepresentativeTableState}
                                          >
                                            <AppLogTableContext.Provider
                                              value={appLogTableState}
                                            >
                                              <UpdateAppLogTableContext.Provider
                                                value={setAppLogTableState}
                                              >
                                                <AppUserTableContext.Provider
                                                  value={appUserTableState}
                                                >
                                                  <UpdateAppUserTableContext.Provider
                                                    value={setAppUserTableState}
                                                  >
                                                    <ContactTableContext.Provider
                                                  value={contactTableState}
                                                >
                                                  <UpdateContactTableContext.Provider
                                                    value={setContactTableState}
                                                  >
                                                    <ConfigVariableContext.Provider
                                                      value={configVariableState}
                                                    >
                                                      <UpdateConfigVariableContext.Provider
                                                      value={setConfigVariableState}
                                                    >
                                                    {children}
                                                    </UpdateConfigVariableContext.Provider>
                                                    </ConfigVariableContext.Provider>
                                                    </UpdateContactTableContext.Provider>
                                                    </ContactTableContext.Provider>
                                                  </UpdateAppUserTableContext.Provider>
                                                </AppUserTableContext.Provider>
                                              </UpdateAppLogTableContext.Provider>
                                            </AppLogTableContext.Provider>
                                          </UpdateRepresentativeTableContext.Provider>
                                        </RepresentativeTableContext.Provider>
                                      </UpdateClientTableContext.Provider>
                                    </ClientTableContext.Provider>
                                  </UpdateActivityTableContext.Provider>
                                </ActivityTableContext.Provider>
                              </UpdateDashboardContext.Provider>
                            </DashboardContext.Provider>
                          </UpdateErrorStatusContext.Provider>
                        </ErrorStatusContext.Provider>
                      </UpdateUserContext.Provider>
                    </UserContext.Provider>
                  </UpdateUserNameContext.Provider>
                </UserNameContext.Provider>
              </UpdateUserEmailContext.Provider>
            </UserEmailContext.Provider>
          </UpdateUserImageContext.Provider>
        </UserImageContext.Provider>
      </UpdateUserPrivilegeContext.Provider>
    </UserPrivilegeContext.Provider>
  );
}
