import React, { useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { isEmpty } from "validator";
//Context
import { UserPrivilegeContext } from "components/GeneralContextProvider/GeneralContextProvider";
//RBAC
import Can from "components/Can/Can";
//General variables
import { privilegeMap, appUserDefault } from "variables/general"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function AppUserDialog(props) {

  const { onAdd, onEdit, editedAppUser, isOpen, setIsOpen, isSee, setIsSee, isEditDisabled } = props;

  const classes = useStyles();

  const userPrivilege = useContext(UserPrivilegeContext);

  const [appUser, setAppUser] = useState(appUserDefault);
  const [appUserError, setAppUserError] = useState({
    email: false,
    note: false,
  });

  useEffect(() => {
    if(editedAppUser.email) {
      setAppUser(editedAppUser);
    } else {
      resetFields()
    }
  }, [editedAppUser]);

  function handleChange(event) {
    const { value, name } = event.target;

    if (name === "email") {
      setAppUser((prevState) => {
        return {
          ...prevState,
          email: value,
        };
      });
    }

    if (name === "privilege_level") {
      setAppUser((prevState) => {
        return {
          ...prevState,
          privilege_level: value,
        };
      });
    }

    if (name === "note") {
      setAppUser((prevState) => {
        return {
          ...prevState,
          note: value,
        };
      });
    }

    validateField(!isEmpty(value.toString()), "email");
  }

  const validateField = (validator, fieldName) => {
    if (validator) {
      setAppUserError((prevState) => {
        return { ...prevState, [fieldName]: false };
      });
      return true;
    } else {
      setAppUserError((prevState) => {
        return { ...prevState, [fieldName]: true };
      });
      return false;
    }
  };

  function validateAll() {
    const check1 = validateField(!isEmpty(appUser.email), "email");
    return check1;
  }

  function resetFields() {
    setAppUser(appUserDefault);
    setAppUserError({
      email: false,
    });
  }

  const handleClose = () => {
    setIsOpen(false);
    setIsSee(false);
  };

  const handleClickSave = () => {
    if (!validateAll()) {
      return;
    }
    if (isSee) {
      onEdit(appUser)
    } else {
      onAdd(appUser);
    }

    resetFields();
    setIsOpen(false);
    setIsSee(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle>Usuario</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <div>
              <TextField
                error={appUserError.email}
                disabled={isEditDisabled}
                onChange={handleChange}
                value={appUser.email}
                name="email"
                label="Email"
                style={{ margin: 8 }}
                placeholder=""
                helperText={
                  appUserError.email && "Este campo no puede estar vacio"
                }
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                select
                disabled={isEditDisabled}
                onChange={handleChange}
                value={appUser.privilege_level}
                name="privilege_level"
                label="Privilegio"
                style={{ margin: 8 }}
                placeholder=""
                helperText=""
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {Object.entries(privilegeMap).map(option => (
                  <MenuItem key={option[0]} value={option[0]}>
                    {option[1]}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                multiline
                disabled={isEditDisabled}
                onChange={handleChange}
                value={appUser.note}
                name="note"
                label="Nota"
                style={{ margin: 8 }}
                placeholder=""
                helperText=""
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClickSave} disabled={isEditDisabled} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

//https://dev.to/alejluperon/react-form-validation-39bi
