import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import LoginPage from "components/LoginPage/LoginPage";
//Core Components
import Admin from "layouts/Admin.js";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import Error404 from "components/Error404/Error404.js";
import { clientId } from "variables/general"
//Context
import { ErrorStatusContext, UserPrivilegeContext, UserContext } from "components/GeneralContextProvider/GeneralContextProvider"

function App() {

  const userPrivilege = useContext(UserPrivilegeContext);
  const errorStatus = useContext(ErrorStatusContext);

  return (
        <Router>
          <GoogleOAuthProvider clientId={clientId}>
            <Switch>
              {!errorStatus && <>
              <Route path="/login" component={LoginPage} />
              <PrivateRoute
                isAuthed={userPrivilege >= 0 ? true : false}
                path="/admin"
                component={Admin}
              />
              <Redirect to="/login" />
              </>
              }
              {errorStatus && <>
              <Route path="/error" component={Error404} />
              <Redirect from="/" to="/error" />
              </>
              }
            </Switch>
          </GoogleOAuthProvider>
        </Router>
  );
}

export default App;
