import React, { useContext, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleButton from "react-google-button";
import { useHistory } from "react-router-dom";
import { 
  UpdateUserContext, 
  UpdateUserNameContext, 
  UpdateUserImageContext, 
  UpdateUserEmailContext, 
  UserPrivilegeContext,
  UpdateUserPrivilegeContext, 
} from "components/GeneralContextProvider/GeneralContextProvider";
import { refreshTokenSetup } from "variables/refreshTokenSetup";
import { baseUrl } from "variables/general"
import axios from "axios";
import { jwtDecode } from "jwt-decode";

function Login() {

  const setUserToken = useContext(UpdateUserContext);
  const setUserName = useContext(UpdateUserNameContext);
  const setUserEmail = useContext(UpdateUserEmailContext);
  const setUserImage = useContext(UpdateUserImageContext);
  const userPrivilege = useContext(UserPrivilegeContext);
  const setUserPrivilege = useContext(UpdateUserPrivilegeContext);

  let history = useHistory();

  useEffect(() => {
    if (userPrivilege >= 0) {
      history.push("/admin/dashboard");
    }
  }, [userPrivilege])

  useEffect(() => {
    axios.get(baseUrl + 'auth/check', { withCredentials: true })
      .then(response => {
        if (response.data.tokens) {
          console.log("Valid cookie found - skipping login")
          onAppAuthSuccess(response)
        }
      })
      .catch(error => {
        onAppAuthFailure(error)
    });
  },[])
 
  const login = useGoogleLogin({
    onSuccess: ({ code }) => {
      axios.post(baseUrl + 'auth/google', 
      { access_token: code }, { withCredentials: true })
        .then(response => {
          onAppAuthSuccess(response)
        })
        .catch(error => {
          onAppAuthFailure(error)
      });
    },
    flow: 'auth-code',
    onError: (error) => onGoogleFailure(error)
  });

  //If user is authorized to use the app
  const onAppAuthSuccess = (res) => {
    console.log("Authentication Success !");
    const userObject = jwtDecode(res.data.tokens.id_token)
    setUserName(userObject.name);
    setUserEmail(userObject.email);
    setUserImage(userObject.picture);
    setUserToken(res.data.tokens.access_token)
    setUserPrivilege(res.data.privilege_level);
    refreshTokenSetup(res.data.tokens.refresh_token, res.data.tokens.expiry_date, setUserToken, setUserPrivilege)
    //history.push("/admin/dashboard");
  }
 
  //If user is not authorized to use the app
  const onAppAuthFailure = (error) => {
    alert("Autenticación con backend fallida !");
    console.error('Error:', error)
  }

  //On Google authentication failure
  const onGoogleFailure = (error) => {
    alert("Google Login ha fallado !")
    console.log("Login failed res:", error);
  };

  return <GoogleButton label="Iniciar sesión con Google" onClick={login} />;
}

export default Login;


//https://github.com/MomenSherif/react-oauth/issues/12
//https://stackoverflow.com/questions/48983708/where-to-store-access-token-in-react-js
//Here we can decode the token ID and its expiration time
//https://jwt.io/