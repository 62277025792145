import { baseUrl } from "variables/general.js";

const curateDate = (inputDate) => {
  const dateYear = inputDate.getFullYear();
  const dateMonth = ("0" + (inputDate.getMonth() + 1)).slice(-2);
  const dateDay = ("0" + inputDate.getDate()).slice(-2);
  const curatedDate = dateYear + "-" + dateMonth + "-" + dateDay;
  return curatedDate;
}

const getAllActivities = (setTableData, onError, token) => {
  const queryUrl = baseUrl + "Activities";
  fetchData(queryUrl, token, setTableData, onError);
};

const getTodayActivities = (setTableData, onError, token) => {
  let today = new Date();
  const dateParams = {
    dateConstrained: 1,
    fromDate: today.toLocaleDateString("fr-CA"),
    toDate: today.toLocaleDateString("fr-CA"),
  };
  //console.log("Querying for activities with date: " + today);
  let queryUrl = new URL(baseUrl + "Activities");
  queryUrl.search = new URLSearchParams(dateParams).toString();
  fetchData(queryUrl, token, setTableData, onError);
};

const getTomorrowActivities = (setTableData, onError, token) => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  //console.log("Querying for activities with date: " + tomorrow);

  const dateParams = {
    dateConstrained: 1,
    fromDate: tomorrow.toLocaleDateString("fr-CA"),
    toDate: tomorrow.toLocaleDateString("fr-CA"),
  };
  let queryUrl = new URL(baseUrl + "Activities");
  queryUrl.search = new URLSearchParams(dateParams).toString();
  fetchData(queryUrl, token, setTableData, onError);
};

const getWeekActivities = (setTableData, onError, token) => {
  let prevMonday = new Date();
  prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
  let nextSunday = new Date(prevMonday.getTime());
  nextSunday.setDate(nextSunday.getDate() + 6);

  // console.log(
  //   "Querying for activities between: " + prevMonday + " and " + nextSunday
  // );

  const dateParams = {
    dateConstrained: 1,
    fromDate: prevMonday.toLocaleDateString("fr-CA"),
    toDate: nextSunday.toLocaleDateString("fr-CA"),
  };
  let queryUrl = new URL(baseUrl + "Activities");
  queryUrl.search = new URLSearchParams(dateParams).toString();
  fetchData(queryUrl, token, setTableData, onError);
};

const getNextWeekActivities = (setTableData, onError, token) => {
  let nextMonday = new Date();
  nextMonday.setDate(nextMonday.getDate() - ((nextMonday.getDay() + 6) % 7));
  nextMonday.setDate(nextMonday.getDate() + 7);
  let nextNextSunday = new Date(nextMonday.getTime());
  nextNextSunday.setDate(nextNextSunday.getDate() + 6);

  // console.log(
  //   "Querying for activities between: " + nextMonday + " and " + nextNextSunday
  // );

  const dateParams = {
    dateConstrained: 1,
    fromDate: nextMonday.toLocaleDateString("fr-CA"),
    toDate: nextNextSunday.toLocaleDateString("fr-CA"),
  };
  let queryUrl = new URL(baseUrl + "Activities");
  queryUrl.search = new URLSearchParams(dateParams).toString();
  fetchData(queryUrl, token, setTableData, onError);
};

const getMonthActivities = (setTableData, onError, token) => {
  //Compute first day of the month
  let firstDayMonth = new Date();
  firstDayMonth.setDate(1);
  //Compute last day of the month
  let currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth();
  let lastDayMonth = new Date(currentYear, currentMonth + 1, 0);

  // console.log(
  //   "Querying for activities between: " + firstDayMonth + " and " + lastDayMonth
  // );

  const dateParams = {
    dateConstrained: 1,
    fromDate: firstDayMonth.toLocaleDateString("fr-CA"),
    toDate: lastDayMonth.toLocaleDateString("fr-CA"),
  };
  let queryUrl = new URL(baseUrl + "Activities");
  queryUrl.search = new URLSearchParams(dateParams).toString();
  fetchData(queryUrl, token, setTableData, onError);
};

const getNextMonthActivities = (setTableData, onError, token) => {
  //Compute first day of the next month
  let firstDayNextMonth = new Date();
  firstDayNextMonth.setMonth(firstDayNextMonth.getMonth()+1);
  firstDayNextMonth.setDate(1);
  //Compute last day of the next month
  let currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth();
  let lastDayNextMonth = new Date(currentYear, currentMonth + 2, 0);

  // console.log(
  //   "Querying for activities between: " + firstDayNextMonth + " and " + lastDayNextMonth
  // );

  const dateParams = {
    dateConstrained: 1,
    fromDate: firstDayNextMonth.toLocaleDateString("fr-CA"),
    toDate: lastDayNextMonth.toLocaleDateString("fr-CA"),
  };
  let queryUrl = new URL(baseUrl + "Activities");
  queryUrl.search = new URLSearchParams(dateParams).toString();
  fetchData(queryUrl, token, setTableData, onError);
};

const getLaggingActivities = (setTableData, filter, onError, token) => {
  let todayDate = new Date();
  let params = {
    lagging: 1,
    todayDate: todayDate.toLocaleDateString("fr-CA"),
    ...filter,
  };
  let queryUrl = new URL(baseUrl + "Activities");
  queryUrl.search = new URLSearchParams(params).toString();
  fetchData(queryUrl, token, setTableData, onError);
};

const getFilteredActivities = (setTableData, filter, onError, token) => {
  let params = {
    all: 1,
    ...filter,
  };
  let queryUrl = new URL(baseUrl + "Activities");
  queryUrl.search = new URLSearchParams(params).toString();
  fetchData(queryUrl, token, setTableData, onError);
};

const createActivity = (body, onSuccess, onError, token) => {
  const queryUrl = baseUrl + "Activities";
  //The date is converted to a string so no timezone is misinterpreted by the frontend or backend
  body.action_date = curateDate(new Date(body.action_date));
  fetch(queryUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((response) => {
      onSuccess(response.status);
    })
    .catch((err) => {
      onError(err);
    });
};

const updateActivity = (body, onSuccess, onError, token) => {
  const queryUrl = baseUrl + "Activities";
  body.action_date = curateDate(new Date(body.action_date));
  fetch(queryUrl, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((response) => {
      onSuccess(response.status);
    })
    .catch((err) => {
      onError(err);
    });
};

const removeActivity = (activityId, onSuccess, onError, token) => {
  const idParam = { id: activityId };
  let queryUrl = new URL(baseUrl + "Activities");
  queryUrl.search = new URLSearchParams(idParam).toString();
  // deletes entities
  fetch(queryUrl, {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((response) => {
      onSuccess(response.status);
    })
    .catch((err) => {
      onError(err);
    });
};

const fetchData = (queryUrl, token, setTableData, onError) => {
  fetch(queryUrl, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((res) => res.json())
    .then((json) => {
      setTableData(json);
    })
    .catch((err) => {
      onError(err);
    });
};


export {
  getAllActivities,
  getLaggingActivities,
  getTodayActivities,
  getTomorrowActivities,
  getWeekActivities,
  getNextWeekActivities,
  getMonthActivities,
  getNextMonthActivities,
  getFilteredActivities,
  createActivity,
  updateActivity,
  removeActivity,
};

//https://dockyard.com/blog/2020/02/14/you-probably-don-t-need-moment-js-anymore
