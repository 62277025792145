import React, { useEffect, useState, useContext } from "react";
import MUIDataTable from "mui-datatables";
import MUIDataTableSpanish from "variables/MUIDataTablesSpanish";
import ActivityModal from "components/ActivityModal/ActivityModal";
import { makeStyles } from "@material-ui/core/styles";
import ErrorModal from "components/ErrorModal/ErrorModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import ScrollableTabPanel from "components/ScrollableTabPanel/ScrollableTabPanel";
import {
  ActivityTableContext,
  UpdateActivityTableContext,
  UpdateErrorStatusContext,
  UserContext,
} from "components/GeneralContextProvider/GeneralContextProvider";
import {
  customCellStyleMedium,
  customCellStyleLarge,
  customCellStyleDate,
  customCellStyleType,
  customCellStyleIsOrder,
  customCellStyleExecuted,
} from "variables/cellStyles";
import {
  getLaggingActivities,
  getTodayActivities,
  getTomorrowActivities,
  getWeekActivities,
  getNextWeekActivities,
  getMonthActivities,
  getNextMonthActivities,
  getFilteredActivities,
  createActivity,
  updateActivity,
  removeActivity,
} from "repositories/ActivityRepository";
import { getAllClients } from "repositories/ClientRepository";
import { getAllRepresentatives } from "repositories/RepresentativeRepository";
import { CircularProgress, Typography, Button } from "@material-ui/core";
import { Menu, MenuItem, MenuList } from '@mui/material'; 
//Context
import { UserPrivilegeContext } from "components/GeneralContextProvider/GeneralContextProvider"
//RBAC
import Can from "components/Can/Can"
//Mapping values from numeric to string. Also the default schema for activity objects. 
import {
  isOrderMap,
  typeMap,
  executedMap,
  activityDefault
} from "variables/general"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  button: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const Activity = () => {

  const classes = useStyles();

  const userPrivilege = useContext(UserPrivilegeContext);

  const [contextSee, setContextSee] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);

  const [open, setOpen] = useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const [editedActivity, setEditedActivity] = useState(activityDefault);
  const [currentView, setCurrentView] = useState(0);
  const [forbiddenQuery, setForbiddenQuery] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [representativeList, setRepresentativeList] = useState([]);

  const [filterState, setFilterState] = useState({});

  const tableState = useContext(ActivityTableContext);
  const setTableState = useContext(UpdateActivityTableContext);
  const setErrorStatus = useContext(UpdateErrorStatusContext);

  const userToken = useContext(UserContext);

  useEffect(() => {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        loading: true,
      };
    });
    getTodayActivities(setTableDataToday, onQueryError, userToken);
    getTomorrowActivities(setTableDataTomorrow, onQueryError, userToken);
    getWeekActivities(setTableDataWeek, onQueryError, userToken);
    getNextWeekActivities(setTableDataNextWeek, onQueryError, userToken);
    getMonthActivities(setTableDataMonth, onQueryError, userToken);
    getNextMonthActivities(setTableDataNextMonth, onQueryError, userToken);
    getAllRepresentatives(setRepresentativeList, onQueryError, userToken); 
    getAllClients(setClientList, onQueryError, userToken); 
  }, []);

  useEffect(() => {
    updateCurrentTable();
  }, [currentView]);

  function updateCurrentTable() {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        loading: true,
      };
    });
    const passAllFilter = {
      filterBySearchBar: 0,
      filterByColumns: 0,
      page: 0,
    };
    switch (currentView) {
      case 0:
        getTodayActivities(setTableDataToday, onQueryError, userToken);
        break;
      case 1:
        getTomorrowActivities(setTableDataTomorrow, onQueryError, userToken);
        break;
      case 2:
        getWeekActivities(setTableDataWeek, onQueryError, userToken);
        break;
      case 3:
        getNextWeekActivities(setTableDataNextWeek, onQueryError, userToken);
        break;
      case 4:
        getMonthActivities(setTableDataMonth, onQueryError, userToken);
        break;
      case 5:
        getNextMonthActivities(setTableDataNextMonth, onQueryError, userToken);
        break;
      case 6:
        getLaggingActivities(setTableDataLagging, passAllFilter, onQueryError, userToken);
        break;
      case 7:
        getFilteredActivities(setTableDataAll, passAllFilter, onQueryError, userToken);
        break;
      default:
        getTodayActivities(setTableDataToday, onQueryError, userToken);
    }
  }

  function addActivity(activity) {
    createActivity(activity, onQuerySuccess, onQueryError, userToken);
  }

  function editActivity(activity) {
    updateActivity(activity, onQuerySuccess, onQueryError, userToken);
  }

  function deleteActivity(activity) {
    let activityId = activity.id;
    removeActivity(activityId, onQuerySuccess, onQueryError, userToken);
  }

  function handleTableChange(action, tableState) {
    if (action === "search" || action === "changePage" || action === "filterChange") {
      let columnFiltersObject = {};
      let isColumnFilterUsed = false;
      //Here we built the parameters object for the request. It includes everything from
      //searchbar string, current page and column filters' strings
      
      for (let counter = 0; counter < tableState.filterList.length; counter++) {
        let fieldName = 'columnFilter' + counter.toString();
        isColumnFilterUsed = isColumnFilterUsed || tableState.filterList[counter][0];
        columnFiltersObject[fieldName] = tableState.filterList[counter][0]; 
      }

      let filter = {
        filterBySearchBar: tableState.searchText ? 1 : 0,
        searchBarString: tableState.searchText,
        page: tableState.page,
        filterByColumns: isColumnFilterUsed ? 1 : 0,
        ...columnFiltersObject,
      };
      setFilterState(filter);
      switch (currentView) {
        case 6:
          getLaggingActivities(setTableDataLagging, filter, onQueryError, userToken);
          break;
        case 7:
          getFilteredActivities(setTableDataAll, filter, onQueryError, userToken);
          break;
        default:
      }
      
    }
  }

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleContextClose = () => {
    setContextMenu(null);
  };

  const handleContextEdit = () => {
    setContextSee(true)
    setOpen(true)
    setContextMenu(null);
  };

  const handleContextDeletePrompt = () => {
    setContextMenu(null)
    setConfirmationModalOpen(true)
  };

  const handleContextDelete = () => {
    if (editedActivity.id) {
      deleteActivity(editedActivity)
      setEditedActivity(activityDefault)
    }
    setContextMenu(null);
  };

  const handleContextExecute = () => {
    if (editedActivity.id) {
      //In order to programatically save edited activity, we make a copy of the activity object, change the executed field, and call editActivity on it. 
      //useState is asynchronous, so using the editedActivity object directly wouldn't work. 
      let activityCopy = {...editedActivity}
      activityCopy.executed = 1
      editActivity(activityCopy)
      setEditedActivity((prevValue) => {
        return {
          ...prevValue,
          executed: 1,
        };
      });
    }
    setContextMenu(null);
  };

  const handleContextUnexecute = () => {
    if (editedActivity.id) {
      let activityCopy = {...editedActivity}
      activityCopy.executed = 0
      editActivity(activityCopy)
      setEditedActivity((prevValue) => {
        return {
          ...prevValue,
          executed: 0,
        };
      });  
    }
    setContextMenu(null);
  };

  const handleClickOpen = () => {
    setEditedActivity(activityDefault)
    setOpen(true);
  };

  function handleRowClick(rowData, rowMeta) {
    const activity = getSelectedActivity(rowMeta.dataIndex)
    setEditedActivity(activity);
  }

  const handleDoubleClick = (row, dataIndex) => {
    handleContextEdit()
  }

  const handleMouseOver = (row, dataIndex) => {
    const activity = getSelectedActivity(dataIndex)
    setEditedActivity(activity)
  }

  const getSelectedActivity = (dataIndex) => {
    let activity = {}
    switch (currentView) {
      case 0:
        activity = tableState.dataToday[dataIndex];
        break;
      case 1:
        activity = tableState.dataTomorrow[dataIndex];
        break;
      case 2:
        activity = tableState.dataWeek[dataIndex];
        break;
      case 3:
        activity = tableState.dataNextWeek[dataIndex];
        break;
      case 4:
        activity = tableState.dataMonth[dataIndex];
        break;
      case 5:
        activity = tableState.dataNextMonth[dataIndex];
        break;
      case 6:
        activity = tableState.dataLagging[dataIndex];
        break;
      case 7:
        activity = tableState.dataAll[dataIndex];
        break;
      default:
        activity = tableState.dataToday[dataIndex];
    }  
    return activity
  }

  function onQuerySuccess(statusCode) {
    if (statusCode === 200) {
      setTableState((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });
      let filter = {};
      switch (currentView) {
        case 0:
          getTodayActivities(setTableDataToday, onQueryError, userToken);
          break;
        case 1:
          getTomorrowActivities(setTableDataTomorrow, onQueryError, userToken);
          break;
        case 2:
          getWeekActivities(setTableDataWeek, onQueryError, userToken);
          break;
        case 3:
          getNextWeekActivities(setTableDataNextWeek, onQueryError, userToken);
          break;
        case 4:
          getMonthActivities(setTableDataMonth, onQueryError, userToken);
          break;
        case 5:
          getNextMonthActivities(setTableDataNextMonth, onQueryError, userToken);
          break;
        case 6:
          getLaggingActivities(setTableDataLagging, filterState, onQueryError, userToken);
          break;
        case 7:
          getFilteredActivities(setTableDataAll, filterState, onQueryError, userToken);
          break;
        default:
          getTodayActivities(setTableDataToday, onQueryError, userToken);
      }
    } else if (statusCode === 403) {
      setForbiddenQuery(true);
    } else {
      //setErrorStatus(true);
    }
  }

  function onQueryError(error) {
    let statusCode = parseInt(error.message);
    if (statusCode === 403) {
      setForbiddenQuery(true);
    } else if (statusCode === 401) {
      //Here goes some modal about unauthorized operation
    } else {
      setErrorStatus(true);
    }
  }

  function setTableDataToday(fetchedData) {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        dataToday: fetchedData,
        loading: false,
      };
    });
  }

  function setTableDataTomorrow(fetchedData) {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        dataTomorrow: fetchedData,
        loading: false,
      };
    });
  }

  function setTableDataWeek(fetchedData) {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        dataWeek: fetchedData,
        loading: false,
      };
    });
  }

  function setTableDataNextWeek(fetchedData) {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        dataNextWeek: fetchedData,
        loading: false,
      };
    });
  }

  function setTableDataMonth(fetchedData) {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        dataMonth: fetchedData,
        loading: false,
      };
    });
  }

  function setTableDataNextMonth(fetchedData) {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        dataNextMonth: fetchedData,
        loading: false,
      };
    });
  }

  function setTableDataLagging(fetchedData) {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        dataLagging: fetchedData.data,
        countLagging: fetchedData.count,
        loading: false,
      };
    });
  }

  function setTableDataAll(fetchedData) {
    setTableState((prevValue) => {
      return {
        ...prevValue,
        dataAll: fetchedData.data,
        countAll: fetchedData.count,
        loading: false,
      };
    });
  }

  const columns = [
    {
      name: "name",
      label: "Cliente",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleLarge,
      },
    },
    {
      name: "client_code",
      label: "RIF",
      options: {
        filter: true,
        sort: true,
        print: false,
        customBodyRender: customCellStyleMedium,
      },
    },

    {
      name: "type",
      label: "Tipo de cliente",
      options: {
        filter: true,
        sort: true,
        customBodyRender: customCellStyleType,
        print: false,
        filterType: "multiselect",
        filterOptions: {
          names: Object.values(typeMap),
          logic(type, filterVal) {
            return !(filterVal.includes(typeMap[type]) || false)
          }
        }
      },
    },
    {
      name: "representative_name",
      label: "Vendedor asignado",
      options: {
        filter: true,
        sort: true,
        print: false,
        customBodyRender: customCellStyleMedium,
      },
    },
    {
      name: "action_date",
      label: "Fecha de actividad",
      options: {
        filter: false,
        sort: true,
        print: false,
        customBodyRender: customCellStyleDate,
      },
    },
    {
      name: "is_order",
      label: "Actividad de Compra?",
      options: {
        filter: true,
        sort: true,
        print: false,
        customBodyRender: customCellStyleIsOrder,
        filterType: "multiselect",
        filterOptions: {
          names: Object.values(isOrderMap),
          logic(isOrder, filterVal) {
            return !(filterVal.includes(isOrderMap[isOrder]) || false)
          }
        }
      },
    },
    {
      name: "executed",
      label: "Ejecutado?",
      options: {
        filter: true,
        sort: true,
        print: false,
        customBodyRender: customCellStyleExecuted,
        filterType: "multiselect",
        filterOptions: {
          names: Object.values(executedMap),
          logic(executed, filterVal) {
            return !(filterVal.includes(executedMap[executed]) || false)
          }
        }
      },
    },
  ];

  const optionsCommon = {
    ...MUIDataTableSpanish,
    selectableRows: "none",
    filterType: "textField",
    filter: true,
    sort: true,
    download: false,
    responsive: "vertical",
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    onRowClick: handleRowClick,
    onRowsDelete: false,
    // sortOrder: {
    //   name: 'name',
    //   direction: 'desc'
    // },
    setRowProps: (row, dataIndex) => {
      const activity = getSelectedActivity(dataIndex)
      const eventHandlers = {
        onDoubleClick: () => {
            handleDoubleClick(row, dataIndex);
        },
        onMouseOver: () => {
            handleMouseOver(row, dataIndex);
        }
      };
      const executedStyle = () => {
        if (Number(activity.executed) === 1) {
            return {
                style: {
                    textDecoration: "line-through",
                    backgroundColor: "#efefef",
                },
            };
        }
      }
      const combinedProps = {
        ...eventHandlers,
        ...executedStyle()
      };

      return combinedProps
    }
    
  };

  const optionsAll = {
    ...optionsCommon,
    sort: false,
    //filter: false,
    count: tableState.countAll,
    serverSide: true,
    onTableChange: handleTableChange,
  };

  const optionsLagging = {
    ...optionsCommon,
    sort: false,
    //filter: false,
    count: tableState.countLagging,
    serverSide: true,
    onTableChange: handleTableChange,
  };

  const optionsToday = {
    ...optionsCommon,
  };

  const optionsTomorrow = {
    ...optionsCommon,
  };

  const optionsWeek = {
    ...optionsCommon,
  };

  const optionsNextWeek = {
    ...optionsCommon,
  };

  const optionsMonth = {
    ...optionsCommon,
  };

  const optionsNextMonth = {
    ...optionsCommon,
  };

  return (
    <div>
      <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
        <ScrollableTabPanel
          onViewChange={setCurrentView}
          table0={
            <MUIDataTable
              title={
                <Typography variant="h6">
                  Actividades para hoy
                  {tableState.loading && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                  )}
                </Typography>
              }
              data={tableState.dataToday}
              columns={columns}
              options={optionsToday}
            />
          }
          table1={
            <MUIDataTable
              title={
                <Typography variant="h6">
                  Actividades para mañana
                  {tableState.loading && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                  )}
                </Typography>
              }
              data={tableState.dataTomorrow}
              columns={columns}
              options={optionsTomorrow}
            />
          }
          table2={
            <MUIDataTable
              title={
                <Typography variant="h6">
                  Actividades para la semana
                  {tableState.loading && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                  )}
                </Typography>
              }
              data={tableState.dataWeek}
              columns={columns}
              options={optionsWeek}
            />
          }
          table3={
            <MUIDataTable
              title={
                <Typography variant="h6">
                  Actividades para la próxima semana
                  {tableState.loading && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                  )}
                </Typography>
              }
              data={tableState.dataNextWeek}
              columns={columns}
              options={optionsNextWeek}
            />
          }
          table4={
            <MUIDataTable
              title={
                <Typography variant="h6">
                  Actividades del mes
                  {tableState.loading && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                  )}
                </Typography>
              }
              data={tableState.dataMonth}
              columns={columns}
              options={optionsMonth}
            />
          }
          table5={
            <MUIDataTable
              title={
                <Typography variant="h6">
                  Actividades del siguiente mes
                  {tableState.loading && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                  )}
                </Typography>
              }
              data={tableState.dataNextMonth}
              columns={columns}
              options={optionsNextMonth}
            />
          }
          table6={
            <MUIDataTable
              title={
                <Typography variant="h6">
                  Actividades rezagadas
                  {tableState.loading && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                  )}
                </Typography>
              }
              data={tableState.dataLagging}
              columns={columns}
              options={optionsLagging}
            />
          }
          table7={
            <MUIDataTable
              title={
                <Typography variant="h6">
                  Todas las actividades
                  {tableState.loading && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                  )}
                </Typography>
              }
              data={tableState.dataAll}
              columns={columns}
              options={optionsAll}
            />
          }
        />

        <Menu
          open={contextMenu !== null}
          onClose={handleContextClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
              <MenuList>
                <MenuItem onClick={handleContextExecute}>Marcar ejecutada</MenuItem>
                <MenuItem onClick={handleContextUnexecute}>Marcar no ejecutada</MenuItem>
                <MenuItem onClick={handleContextEdit}>Ver/Editar</MenuItem>
                <MenuItem disabled={isDeleteDisabled} onClick={handleContextDeletePrompt}>Borrar</MenuItem>
              </MenuList>
        </Menu>
      </div>
      <Can 
          role={userPrivilege} 
          perform="activities:edit"
          data={[]} 
          yes={() => {
            setIsEditDisabled(false);
            return null
          }}
          no={() => {
            setIsEditDisabled(true);
            return null
          }}
      />
      <Can 
          role={userPrivilege} 
          perform="activities:delete"
          data={[]} 
          yes={() => {
            setIsDeleteDisabled(false);
            return null
          }}
          no={() => {
            setIsDeleteDisabled(true);
            return null
          }}
      />
      <Button
        disabled={isEditDisabled}
        variant="contained"
        size="large"
        color="primary"
        onClick={handleClickOpen}
        className={classes.margin}
      >
        Agregar
      </Button>
      <ActivityModal
        onAdd={addActivity}
        onEdit={editActivity}
        clients={clientList}
        representatives={representativeList}
        isOpen={open}
        setIsOpen={setOpen}
        isSee={contextSee}
        setIsSee={setContextSee}
        isEditDisabled={isEditDisabled}
        editedActivity={editedActivity}
      />
      <ConfirmationModal 
        isOpen={confirmationModalOpen}
        setIsOpen={setConfirmationModalOpen}
        onSuccess={handleContextDelete}
      />
      {forbiddenQuery && <ErrorModal setOpen={setForbiddenQuery} />}
    </div>
  );
};

export default Activity;

//https://www.smashingmagazine.com/2020/06/rest-api-react-fetch-axios/
//https://itnext.io/centralizing-api-error-handling-in-react-apps-810b2be1d39d
