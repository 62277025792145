import React, { useState, useContext, useEffect} from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { isEmpty } from "validator";
//Context
import { UserPrivilegeContext } from "components/GeneralContextProvider/GeneralContextProvider"
//RBAC
import Can from "components/Can/Can"
//General variables
import { statusMap, representativeDefault } from "variables/general"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function RepresentativeDialog(props) {

  const { onAdd, onEdit, editedRepresentative, isOpen, setIsOpen, isSee, setIsSee, isEditDisabled } = props;

  const classes = useStyles();

  const [representative, setRepresentative] = useState(representativeDefault);
  const [representativeError, setRepresentativeError] = useState({
    fname: false,
    lname: false,
    contact_info: false,
    note: false,
    status: false,
  });

  useEffect(() => {
    if(editedRepresentative.fname) {
      setRepresentative(editedRepresentative);
    } else {
      resetFields()
    }
  }, [editedRepresentative]);

  function handleChange(event) {
    const { value, name } = event.target;

    if (name === "fname") {
      setRepresentative((prevState) => {
        return {
        ...prevState,
        fname: value,
        }
      });
    }

    if (name === "lname") {
      setRepresentative((prevState) => {
        return {
        ...prevState,
        lname: value,
        }
      });
    }

    if (name === "contact_info") {
      setRepresentative((prevState) => {
        return {
        ...prevState,
        contact_info: value,
        }
      });
    }

    if (name === "note") {
      setRepresentative((prevState) => {
        return {
        ...prevState,
        note: value,
        }
      });
    }

    if (name === "status") {
      setRepresentative((prevState) => {
        return {
        ...prevState,
        status: value,
        }
      });
    }
    
    validateField(!isEmpty(value.toString()), name);
  }

  const validateField = (validator, fieldName) => {
    if (validator) {
      setRepresentativeError((prevState) => {
        return { ...prevState, [fieldName]: false };
      });
      return true;
    } else {
      setRepresentativeError((prevState) => {
        return { ...prevState, [fieldName]: true };
      });
      return false;
    }
  };

  function validateAll() {
    const check1 = validateField(!isEmpty(representative.fname), "fname");
    const check2 = validateField(!isEmpty(representative.lname), "lname");
    return (check1 && check2)
  }

  function resetFields() {
    setRepresentative(representativeDefault);
    setRepresentativeError({
      fname: false,
      lname: false,
      contact_info: false,
      note: false,
      status: false,
    });
  }

  const handleClose = () => {
    //resetFields();
    setIsOpen(false);
    setIsSee(false);
  };

  const handleClickSave = () => {
    if (!validateAll()) {
      return;
    }
    if (isSee) {
      console.log("Editing")
      onEdit(representative)
    } else {
      console.log("Creating")
      onAdd(representative);
    }
    resetFields();
    setIsOpen(false);
    setIsSee(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle>Vendedor</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <div>
              <TextField
                error={representativeError.fname}
                disabled={isEditDisabled}
                onChange={handleChange}
                value={representative.fname}
                name="fname"
                label="Nombre"
                style={{ margin: 8 }}
                placeholder=""
                helperText={
                  representativeError.fname && "Este campo no puede estar vacio"
                }
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                error={representativeError.lname}
                disabled={isEditDisabled}
                onChange={handleChange}
                value={representative.lname}
                name="lname"
                label="Apellido"
                style={{ margin: 8 }}
                placeholder=""
                helperText={
                    representativeError.lname && "Este campo no puede estar vacio"
                  }
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                multiline
                disabled={isEditDisabled}
                onChange={handleChange}
                value={representative.contact_info}
                name="contact_info"
                label="Email"
                style={{ margin: 8 }}
                placeholder=""
                helperText=""
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                multiline
                disabled={isEditDisabled}
                onChange={handleChange}
                value={representative.note}
                name="note"
                label="Nota"
                style={{ margin: 8 }}
                placeholder=""
                helperText=""
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                select
                error={representativeError.status}
                disabled={isEditDisabled}
                onChange={handleChange}
                value={representative.status}
                name="status"
                label="Estado"
                className={classes.textField}
                helperText=""
                margin="normal"
              >
                {Object.entries(statusMap).map(option => (
                  <MenuItem key={option[0]} value={option[0]}>
                    {option[1]}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClickSave} disabled={isEditDisabled} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

//https://dev.to/alejluperon/react-form-validation-39bi