import React, {useState, useEffect} from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    mainDiv: {
      marginTop: "50px",
      align: "left",
      borderRadius: "1.5em",
      boxShadow: "0px 11px 35px 2px rgba(0, 0, 0, 0.14)",
    },
    labelText: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    textField: {
      marginBottom: "20px"
    }
  }));

export default function NotificationPanel(props) {

  const { onEdit, configVariableData } = props;

  const classes = useStyles();

  const [state, setState] = useState({});

  const [emailValue, setEmailValue] = useState("");

  const [emailInputValue, setEmailInputValue] = useState("");

  useEffect(() => {
    setState(configVariableData);
  }, [configVariableData]);

  useEffect(() => {
    onEdit(state);
  }, [state]);

  const handleChange = (event) => { 

    const { value, name, checked } = event.target;

    if (name === "email") {
      setState((prevState) => {
        return {
        ...prevState,
        email: value,
        }
      });
    }

    if (name === "companyAnniversary") {
      setState((prevState) => {
        return {
        ...prevState,
        company_anniversary: checked ? 1:0,
        }
      });
    }

    if (name === "contactBirthday") {
      setState((prevState) => {
        return {
        ...prevState,
        contact_birthday: checked ? 1:0,
        }
      });
    }

    if (name === "unattendedClients") {
      setState((prevState) => {
        return {
        ...prevState,
        unattended_clients: checked ? 1:0,
        }
      });
    }

    if (name === "dayActivities") {
      setState((prevState) => {
        return {
        ...prevState,
        day_activities: checked ? 1:0,
        }
      });
    }

    if (name === "unexecutedActivities") {
      setState((prevState) => {
        return {
        ...prevState,
        unexecuted_activities: checked ? 1:0,
        }
      });
    }

  };

  return (

    <div className={classes.mainDiv}>
        <DialogTitle id="form-dialog-title">
            Panel de notificaciones
        </DialogTitle>
        <DialogContent>
        <FormControl component="fieldset">
            <div className={classes.labelText}>
              <FormLabel component="legend">Habilitar/deshabilitar notificaciones</FormLabel>
            </div>
            <FormGroup row>
                <FormControlLabel
                control={<Switch checked={state.company_anniversary == 1 ? true : false} onChange={handleChange} name="companyAnniversary" />}
                label="Aniversario de empresas"
                />
                <FormControlLabel
                control={<Switch checked={state.contact_birthday == 1 ? true : false} onChange={handleChange} name="contactBirthday" />}
                label="Cumpleaños de contactos"
                />
                <FormControlLabel
                control={<Switch checked={state.unattended_clients == 1 ? true : false} onChange={handleChange} name="unattendedClients" />}
                label="Clientes desatendidos"
                />
                <FormControlLabel
                  control={<Switch checked={state.day_activities == 1 ? true : false} onChange={handleChange} name="dayActivities" />}
                  label="Actividades del día"
                />
                <FormControlLabel
                  control={<Switch checked={state.unexecuted_activities == 1 ? true : false} onChange={handleChange} name="unexecutedActivities" />}
                  label="Actividades sin ejecutar"
                />
            </FormGroup>
            <div className={classes.labelText}>
              <FormLabel component="legend">Destinatarios de notificaciones</FormLabel>
            </div>
            <TextField
               multiline
               name="email"
               className={classes.textField}
               onChange={handleChange}
               value={state.email}
               label="Correo"
               InputLabelProps={{
                shrink: true,
               }}
               variant="outlined"
            />
            <FormGroup>
            </FormGroup>
        </FormControl>
        </DialogContent>
    </div>
  );
}