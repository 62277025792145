import React, { useState, useEffect, useContext } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import FolderOpen from "@material-ui/icons/FolderOpen";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// core components
import MUIDataTable from "mui-datatables";
import MUIDataTableSpanish from "variables/MUIDataTablesSpanish";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ErrorModal from "components/ErrorModal/ErrorModal";
// Context
import { UpdateErrorStatusContext, UserContext, DashboardContext, UpdateDashboardContext } from "components/GeneralContextProvider/GeneralContextProvider"
import {
  customCellStyleSmall,
  customCellStyleLarge,
  customCellStyleDate,
  customCellStyleType,
} from "variables/cellStyles";

import {
  getCountTodayActivities,
  getCountWeekActivities,
  getCountLaggingActivities,
  getUnattendedClients,
  getNoPlannedActivitiesClients,
  getCountActiveClients, 
  getCountInactiveClients,
} from "repositories/DashboardRepository.js";
import {
  createActivity,
} from "repositories/ActivityRepository";
import {
  getAllClients,
} from "repositories/ClientRepository";
import {
  getAllRepresentatives,
} from "repositories/RepresentativeRepository";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  // const [countToday, setCountToday] = useState(0);
  // const [countWeek, setCountWeek] = useState(0);
  // const [countLagging, setCountLagging] = useState(0);
  // const [unattendedClients, setUnattendedClients] = useState([]);

  const dashboardStatus = useContext(DashboardContext);
  const setDashboardStatus = useContext(UpdateDashboardContext);

  const userToken = useContext(UserContext);
  const setErrorStatus = useContext(UpdateErrorStatusContext);

  const [forbiddenQuery, setForbiddenQuery] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [representativeList, setRepresentativeList] = useState([]);
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});

  useEffect(() => {
    getCountTodayActivities(setCountToday, onQueryError, userToken);
    getCountWeekActivities(setCountWeek, onQueryError, userToken);
    getCountLaggingActivities(setCountLagging, onQueryError, userToken);
    getUnattendedClients(setUnattendedClients, onQueryError, userToken);
    getNoPlannedActivitiesClients(setNoPlannedActivitiesClients, onQueryError, userToken);
    getCountActiveClients(setCountActiveClients, onQueryError, userToken);
    getCountInactiveClients(setCountInactiveClients, onQueryError, userToken);
    getAllClients(setClientList, onQueryError, userToken); 
    getAllRepresentatives(setRepresentativeList, onQueryError, userToken);
  }, []);

  function addActivity(activity) {
    createActivity(activity, onQuerySuccess, onQueryError, userToken);
  }

  function setCountToday(data) {
    setDashboardStatus((prevValue) => {
      return {
        ...prevValue,
        countToday: data,
      }
    });
  }

  function setCountWeek(data) {
    setDashboardStatus((prevValue) => {
      return {
        ...prevValue,
        countWeek: data,
      }
    });
  }

  function setCountLagging(data) {
    setDashboardStatus((prevValue) => {
      return {
        ...prevValue,
        countLagging: data,
      }
    });
  }

  function setUnattendedClients(data) {
    setDashboardStatus((prevValue) => {
      return {
        ...prevValue,
        unattendedClients: data,
      }
    });
  }

  function setNoPlannedActivitiesClients(data) {
    setDashboardStatus((prevValue) => {
      return {
        ...prevValue,
        noPlannedActivitiesClients: data,
      }
    });
  }

  function setCountActiveClients(data) {
    setDashboardStatus((prevValue) => {
      return {
        ...prevValue,
        countActiveClients: data,
      }
    });
  }

  function setCountInactiveClients(data) {
    setDashboardStatus((prevValue) => {
      return {
        ...prevValue,
        countInactiveClients: data,
      }
    });
  }

  function onQueryError(error) {
    let statusCode = parseInt(error.message);
    if (statusCode===401) {
      //Here goes some modal about unauthorized operation
    } else {
      setErrorStatus(true);
    }
  }

  function handleRowClick(rowData, rowMeta) {

  }

  function onQuerySuccess(statusCode) {
    if (statusCode === 200) {
      setDashboardStatus((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });
      getNoPlannedActivitiesClients(setNoPlannedActivitiesClients, onQueryError, userToken);
    } else if (statusCode === 403) {
      setForbiddenQuery(true);
    } else {
      //setErrorStatus(true);
    }
  }

  const today = new Date().toLocaleDateString("spa", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  let prevMonday = new Date();
  prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
  let nextSunday = new Date(prevMonday.getTime());
  nextSunday.setDate(nextSunday.getDate() + 6);
  prevMonday = prevMonday.toLocaleDateString("spa", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  nextSunday = nextSunday.toLocaleDateString("spa", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  const columns = [
    {
      name: "client_name",
      label: "Cliente",
      options: {
        filter: true,
        //sort: true,
        customBodyRender: customCellStyleLarge,
      },
    },
    {
      name: "client_code",
      label: "RIF",
      options: {
        filter: false,
        //sort: false,
        customBodyRender: customCellStyleSmall,
      },
    },
    {
      name: "type",
      label: "Tipo",
      options: {
        filter: false,
        //sort: false,
        customBodyRender: customCellStyleType,
      },
    },
    {
      name: "action_date",
      label: "Última actividad ejecutada",
      options: {
        filter: false,
        //sort: false,
        customBodyRender: customCellStyleDate,
      },
    },
  ];

  const optionsUnattendedClients = {
    ...MUIDataTableSpanish,
    filterType: "textField",
    filter: false,
    sort: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    onRowsDelete: () => {
      return false;
    },
  };

  const optionsNoPlannedActivitiesClients = {
    ...MUIDataTableSpanish,
    filterType: "textField",
    filter: false,
    //sort: true,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    onRowClick: handleRowClick,
    onRowsDelete: () => {
      return false;
    },
  };

  const classes = useStyles();
  return (
    <div>
      <GridContainer justifyContent="center">
        <GridItem xs={12} md={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <FolderOpen />
              </CardIcon>
              <p className={classes.cardCategory}>
                # Actividades por ejecutar para hoy
              </p>
              <h3 className={classes.cardTitle}>{dashboardStatus.countToday}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                {today}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <FolderOpen />
              </CardIcon>
              <p className={classes.cardCategory}>
                # Actividades por ejecutar para la semana
              </p>
              <h3 className={classes.cardTitle}>{dashboardStatus.countWeek}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                {prevMonday + " - " + nextSunday}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} md={4}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <FolderOpen />
              </CardIcon>
              <p className={classes.cardCategory}># Actividades rezagadas</p>
              <h3 className={classes.cardTitle}>{dashboardStatus.countLagging}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Desde siempre
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} md={4}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <AccountCircleIcon/>
              </CardIcon>
              <p className={classes.cardCategory}># Clientes activos</p>
              <h3 className={classes.cardTitle}>{dashboardStatus.countActiveClients}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                {today}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <AccountCircleIcon/>
              </CardIcon>
              <p className={classes.cardCategory}># Clientes inactivos</p>
              <h3 className={classes.cardTitle}>{dashboardStatus.countInactiveClients}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                {today}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>Atención</h4>
              <p className={classes.cardCategoryWhite}>
                Clientes activos con mas de 3 semanas sin atender (y sin actividades planificadas dentro de los próximos 31 días)
              </p>
            </CardHeader>
            <CardBody>
              <MUIDataTable
                data={dashboardStatus.unattendedClients}
                columns={columns}
                options={optionsUnattendedClients}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>Atención</h4>
              <p className={classes.cardCategoryWhite}>
                Clientes activos sin actividades planificadas
              </p>
            </CardHeader>
            <CardBody>
              <MUIDataTable
                data={dashboardStatus.noPlannedActivitiesClients}
                columns={columns}
                options={optionsNoPlannedActivitiesClients}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {forbiddenQuery && <ErrorModal setOpen={setForbiddenQuery} />}
    </div>
  );
}
