import { baseUrl } from "variables/general.js"

const getCountTodayActivities = (setTableData, onError, token) => {
  const params = {
    count: true,
    fromDate: new Date().toLocaleDateString("fr-CA"),
    toDate: new Date().toLocaleDateString("fr-CA"),
  };
  let queryUrl = new URL(baseUrl + "Activities");
  queryUrl.search = new URLSearchParams(params).toString();
  fetch(queryUrl, {
    method: "GET",
    headers: {
      "Authorization": [token],
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      return res;
    })
    .then((res) => res.json())
    .then((fetchedData) => {
      setTableData(fetchedData[0].count);
    })
    .catch((err) => {
      onError(err);
    });
};

const getCountWeekActivities = (setTableData, onError, token) => {
  let prevMonday = new Date();
  prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
  let nextSunday = new Date(prevMonday.getTime());
  nextSunday.setDate(nextSunday.getDate() + 6);

  const params = {
    count: true,
    fromDate: prevMonday.toLocaleDateString("fr-CA"),
    toDate: nextSunday.toLocaleDateString("fr-CA"),
  };
  let queryUrl = new URL(baseUrl + "Activities");
  queryUrl.search = new URLSearchParams(params).toString();
  fetch(queryUrl, {
    method: "GET",
    headers: {
      "Authorization": [token],
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      return res;
    })
    .then((res) => res.json())
    .then((fetchedData) => {
      setTableData(fetchedData[0].count);
    })
    .catch((err) => {
      onError(err);
    });
};

const getCountLaggingActivities = (setTableData, onError, token) => {
  let todayDate = new Date()
  const params = {
    countLagging: true,
    todayDate: todayDate.toLocaleDateString("fr-CA"),
  };
  let queryUrl = new URL(baseUrl + "Activities");
  queryUrl.search = new URLSearchParams(params).toString();
  fetch(queryUrl, {
    method: "GET",
    headers: {
      "Authorization": [token],
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      return res;
    })
    .then((res) => res.json())
    .then((fetchedData) => {
      setTableData(fetchedData[0].count);
    })
    .catch((err) => {
      onError(err);
    });
};

const getUnattendedClients = (setTableData, onError, token) => {
  let today = new Date();
  let lowerThresholdDate = new Date();
  let upperThresholdDate = new Date();
  lowerThresholdDate.setDate(today.getDate() - 21);
  upperThresholdDate.setDate(today.getDate() + 31)
  const params = {
    unattended: true,
    lowerThresholdDate: lowerThresholdDate.toLocaleDateString("fr-CA"),
    today: today.toLocaleDateString("fr-CA"),
    upperThresholdDate: upperThresholdDate.toLocaleDateString("fr-CA"),
  };
  let queryUrl = new URL(baseUrl + "Clients");
  queryUrl.search = new URLSearchParams(params).toString();
  fetch(queryUrl, {
    method: "GET",
    headers: {
      "Authorization": [token],
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      return res;
    })
    .then((res) => res.json())
    .then((fetchedData) => {
      setTableData(fetchedData);
    })
    .catch((err) => {
      onError(err);
    });
};


const getNoPlannedActivitiesClients = (setTableData, onError, token) => {
  let today = new Date();
  const params = {
    noPlannedActivities: true,
    today: today.toLocaleDateString("fr-CA"),
  };
  let queryUrl = new URL(baseUrl + "Clients");
  queryUrl.search = new URLSearchParams(params).toString();
  fetch(queryUrl, {
    method: "GET",
    headers: {
      "Authorization": [token],
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      return res;
    })
    .then((res) => res.json())
    .then((fetchedData) => {
      setTableData(fetchedData);
    })
    .catch((err) => {
      onError(err);
    });
};


const getCountActiveClients = (setTableData, onError, token) => {
  let today = new Date();
  const params = { 
    countStatus: 1, 
    status: 1,
  };
  let queryUrl = new URL(baseUrl + "Clients");
  queryUrl.search = new URLSearchParams(params).toString();

  fetch(queryUrl, {
    method: "GET",
    headers: {
      "Authorization": [token],
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((res) => res.json())
    .then((fetchedData) => {
      setTableData(fetchedData[0].count);
    })
    .catch((err) => {
      onError(err);
    });
};

const getCountInactiveClients = (setTableData, onError, token) => {
  let today = new Date();
  const params = { 
    countStatus: 1, 
    status: 0,
  };
  let queryUrl = new URL(baseUrl + "Clients");
  queryUrl.search = new URLSearchParams(params).toString();

  fetch(queryUrl, {
    method: "GET",
    headers: {
      "Authorization": [token],
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.status);
      }
      return res;
    })
    .then((res) => res.json())
    .then((fetchedData) => {
      setTableData(fetchedData[0].count);
    })
    .catch((err) => {
      onError(err);
    });
};

export {
  getCountTodayActivities,
  getCountWeekActivities,
  getCountLaggingActivities,
  getUnattendedClients,
  getNoPlannedActivitiesClients,
  getCountActiveClients, 
  getCountInactiveClients,
};

//https://dockyard.com/blog/2020/02/14/you-probably-don-t-need-moment-js-anymore
