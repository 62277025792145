import { useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmationDialog(props) {

  const { isOpen, setIsOpen, onSuccess } = props;

  const [open, setOpen] = useState(false);

  const handleConfirm = () => {
    onSuccess()
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle>
          Ventana de confirmación
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Está seguro de la acción?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleConfirm} autoFocus>
            Si
          </Button>
        </DialogActions>
      </Dialog>
  );
}