import React, { useContext } from "react";
import { googleLogout } from '@react-oauth/google';
import { useHistory } from "react-router-dom";
import { baseUrl } from "variables/general"; 
import axios from "axios";
//Context
import { UpdateUserContext, UpdateUserPrivilegeContext } from "components/GeneralContextProvider/GeneralContextProvider"


function Logout() {

  const setUserToken = useContext(UpdateUserContext);
  const setUserPrivilege = useContext(UpdateUserPrivilegeContext); 

  let history = useHistory();

  const logOut= () => {
    axios.get(baseUrl + 'auth/logout', { withCredentials: true })
      .then(response => {
        setUserPrivilege(-1);
        setUserToken("");
        googleLogout();
        console.log("Logout Success!");
        history.push("/login");
      })
      .catch(error => {
        console.error("Error logging out to backend")
    });
    
  };


  return (
    <a
        onClick={logOut}
      >
        Cerrar sesión
    </a>
  );
}
 


export default Logout;
