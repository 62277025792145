const rules = {
  //User with low privileges
  0: {
    static: [
      "clients:read",
      "activities:read",
      "activities:edit",
      "activities:delete",
      "representatives:read",
      "contacts:read",
    ],
    dynamic: {
      // "activities:edit": ({ userEmail, clientOwnerEmail }) => {
      //   if (!userEmail || !clientOwnerEmail) return false;
      //   return userEmail === clientOwnerEmail;
      // },
      // "clients:edit": ({ userEmail, clientOwnerEmail }) => {
      //   if (!userEmail || !clientOwnerEmail) return false;
      //   return userEmail === clientOwnerEmail;
      // },
    },
  },
  //User with high privileges
  1: {
    static: [
      "clients:read",
      "clients:edit",
      "activities:read",
      "activities:edit",
      "activities:delete",
      "representatives:read",
      "representatives:edit",
      "representatives:delete",
      "contacts:read",
      "contacts:edit",
      "contacts:delete",
      "logs:read",
    ],
  },
  //Admin
  2: {
    static: [
      "clients:read",
      "clients:edit",
      "clients:delete",
      "activities:read",
      "activities:edit",
      "activities:delete",
      "representatives:read",
      "representatives:edit",
      "representatives:delete",
      "contacts:read",
      "contacts:edit",
      "contacts:delete",
      "logs:read",
      "adminDashboard:read",
      "appUsers:read",
      "appUsers:edit",
      "appUsers:delete"
    ],
  },
};

export default rules;

//https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/