import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import spaLocale from "date-fns/locale/es";
import MenuItem from "@material-ui/core/MenuItem";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    marginTop: "50px",
    align: "left",
    borderRadius: "1.5em",
    boxShadow: "0px 11px 35px 2px rgba(0, 0, 0, 0.14)",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  button: {
    margin: "10px",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  inputReadOnly: {
    color: "grey",
  }
}));

export default function RepresentativeForm(props) {
  const { selectedLog } = props;

  const classes = useStyles();

  const [log, setLog] = useState(selectedLog);

  useEffect(() => {
    setLog(selectedLog);
  }, [selectedLog]);

  const statusValues = [
    {
      value: 0,
      label: "Inactivo",
    },
    {
      value: 1,
      label: "Activo",
    },
  ];

  return (
    <div className={classes.mainDiv}>
      <DialogTitle id="form-dialog-title">
        Log
      </DialogTitle>
      <DialogContent>
            <div>
              <TextField
                disabled
                value={log.log_type}
                name="log_type"
                label="Tipo"
                style={{ margin: 8 }}
                placeholder=""
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={
                  { className: classes.inputReadOnly, readOnly: true, }
                }
              />
              <TextField
                disabled
                multiline
                rows={2}
                value={log.log_description}
                name="description"
                label="Descripción"
                style={{ margin: 8 }}
                placeholder=""
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={
                  { className: classes.inputReadOnly, readOnly: true, }
                }
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={spaLocale}>
                <KeyboardDatePicker
                  style={{ margin: 16, marginLeft: 8 }}
                  label="Fecha"
                  value={log.log_date}
                  format="dd/MM/yyyy"
                  readOnly={true}
                  inputProps={
                    { className: classes.inputReadOnly, readOnly: true, }
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
      </DialogContent>
    </div>
  );
}
