import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@material-ui/core/styles";
import rosefield from "assets/img/rosefield.jpg";

const useStyles = makeStyles((theme) => ({
  FourOhFour: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    background: "#121212",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  bg: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    backgroundSize: "cover",
    mixBlendMode: "overlay",
  },

  code: {
    fontFamily: "'Alfa Slab One', cursive",
    fontSize: "160px",
    height: "100vh",
    color: "white",
    width: "100%",
    display: "flex",
    backgroundPosition: "center",
    alignItems: "center",
    backgroundSize: "cover",
    justifyContent: "center",
    backgroundImage: "url(" + rosefield + ")",
  },
}));

const NotFound = () => {
  const classes = useStyles();
  return (
    <div className={classes.FourOhFour}>
      <div className={classes.bg}></div>
        <div className={classes.code}>
            Error 404
        </div>
    </div>
  );
};

export default NotFound;
