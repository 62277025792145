import { baseUrl } from "variables/general.js"

const getAllAppUsers = (setTableData, onError, token) => {
  const queryUrl = baseUrl + "AppUsers";

  fetch(queryUrl, {
    method: "GET",
    headers: {
      "Authorization": token,
    },
  })
    .then((res) => res.json())
    .then((fetchedData) => {
      //let options = { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit"}
      fetchedData.forEach(row => {
        if (row.last_seen == null) {
          row.last_seen = "Nunca";
        } else {
          //Leaving this date conversion out for now. Is is causing an invalid date error. 
          //row.last_seen = new Date(row.last_seen).toLocaleString("es-US", options);
        }
      });
      setTableData(fetchedData);
    })
    .catch((err) => {
      onError(err);
    });
};

const getAppUserPrivilege = (email, onAuthSuccess, onAuthFailure, onError, token) => {
  let queryUrl = new URL(baseUrl + "LoginPriv");
  let params = {
    userPrivileges: 1,
  };
  queryUrl.search = new URLSearchParams(params).toString();
  fetch(queryUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      "Authorization": token,
    },
    body: JSON.stringify({ email: email }),
  })
    .then((res) => res.json())
    .then((fetchedData) => {
      if(fetchedData.length > 0) {
        onAuthSuccess(fetchedData[0]);
      } else {
        onAuthFailure();
      }
    })
    .catch((err) => {
      onError(err);
    });
};

const createAppUser = (body, onSuccess, onError, token) => {
  const queryUrl = baseUrl + "AppUsers";
  // creates entity
  fetch(queryUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      "Authorization": token,
    },
    body: JSON.stringify(body),
  })
  .then((res) => {
    if (!res.ok) {
      throw Error(res.status);
    }
    return res;
  })
    .then((response) => {
      onSuccess(response.status);
    })
    .catch((err) => {
      onError(err);
    });
};

const updateAppUser = (body, onSuccess, onError, token) => {
  // this will update entries with PUT
  const queryUrl = baseUrl + "AppUsers";
  fetch(queryUrl, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      "Authorization": token,
    },
    body: JSON.stringify(body),
  })
  .then((res) => {
    if (!res.ok) {
      throw Error(res.status);
    }
    return res;
  })
    .then((response) => {
      onSuccess(response.status);
    })
    .catch((err) => {
      onError(err);
    });
};

const removeAppUser = (appuserId, onSuccess, onError, token) => {
  const idParam = { id: appuserId };
  var queryUrl = new URL(baseUrl + "AppUsers");
  queryUrl.search = new URLSearchParams(idParam).toString();
  // deletes entities
  fetch(queryUrl, {
    method: "DELETE",
    headers: {
      "Authorization": token,
    },
  })
  .then((res) => {
    if (!res.ok) {
      throw Error(res.status);
    }
    return res;
  })
  .then((response) => {
    onSuccess(response.status);
  })
  .catch((err) => {
    onError(err);
  });
};

export { getAllAppUsers, getAppUserPrivilege, createAppUser, updateAppUser, removeAppUser };
